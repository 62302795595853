import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Tab, Nav, Spinner } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import { getReportEmployeeList } from '../../../store/actions/EmployeeActions';
import { updateReportDetails, approverApprovalReportDetails, getreportDrillData, updateClientReportStatusDetails, reportSendBackDetails, getPendingCountData, getPeportProgressData } from '../../../store/actions/TimeCardActions';
import { sendToReviewerData, getNoteData, addNoteData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import ChatBox from "../Components/ChatBox";
import GenerateTimeCard from "../Components/GenerateTimeCard";
import ProgressReport from "../Components/ProgressReport";
import ReactPaginate from "react-paginate";

const ClientApprover = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState(1);
    const [reportType, setReportType] = useState(null);
    const [cardNonList, setCardNonList] = useState([]);
    const [cardList, setCardList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [timeModal, setTimeModal] = useState(false);
    //note
    const [message, setMessage] = useState('');
    const [projectId, setProjectId] = useState(null);
    const [status, setStatus] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(null);
    const [details, setDetails] = useState(null);
    const [commentModal, setCommentModal] = useState(false);
    const [comment, setComment] = useState('');
    const [weeklyOff, setWeeklyOff] = useState(false);
    const [isComment, setIsComment] = useState(false);
    const [textComment, setTextComment] = useState('');

    //filter
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [empId, setEmpId] = useState('');
    const [drill, setDrill] = useState({
        perPage: 10,
        page: 1,
        date: '',
        projectId: '',
        generateBy: ''
    });


    useEffect(() => {
        getReportDetails();
    }, []);

    const getReportDetails = () => {
        dispatch(globalLoadingAction(true));
        dispatch(approverApprovalReportDetails(empId, page, perPage, props.history));
        dispatch(getReportEmployeeList(props.history));
        dispatch(getPendingCountData(props.history));
    }


    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onGetNoteHandler = (task_id) => {
        setProjectId(task_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(task_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onViewHalder = (item) => {
        if (item) {
            let array = JSON.parse(item.report_data);
            if (item.track_type == 0) {
                let array2 = JSON.parse(item.non_billable_report_data);
                setCardNonList(array2);
            }
            setReportId(item._id);
            setStatus(item.status);
            //setAddModal(true);
            setCardList(array);
            setDetails(item);
            setReportType(item.track_type);
            setWeeklyOff(item.weekly_off);
            dispatch(getPeportProgressData(item._id, item.project_id, progressCallBack, props.history));
        }
    }

    const progressCallBack = () => {
        setAddModal(true);
    }

    const updateCallBack = () => {
        const timer = setTimeout(() => {
            setReportId(null);
            setStatus(null);
            setLoadingStatus(null);
            setAddModal(false);
            setLoading(false);
            setCardList([]);
            dispatch(getPendingCountData(props.history));
            getReportDetails();
            setComment('');
            setCommentModal(false);
        }, 2000);

        // Return a cleanup function
        return () => clearTimeout(timer);
    }
    // const onUpdateHandler = () => {
    //     setLoading(true);
    //     dispatch(updateReportDetails(reportId, cardList, updateCallBack, props.history));
    // }

    const onStatusHandler = (status) => {
        setLoadingStatus(status);
        setLoading(true);
        dispatch(updateClientReportStatusDetails(reportId, status, updateCallBack, history));
    }
    const onSendBackHandler = (status) => {
        setLoading(true);
        setLoadingStatus(status);
        dispatch(reportSendBackDetails(reportId, status, comment, updateCallBack, history));
    }

    const onFilterEmpHandler = (id) => {
        dispatch(globalLoadingAction(true));
        dispatch(approverApprovalReportDetails(id, 1, perPage, props.history));
        setPage(1);
        setEmpId(id);
    }

    const handleCartPageClick = (data) => {
        dispatch(globalLoadingAction(true));
        dispatch(approverApprovalReportDetails(empId, data.selected + 1, perPage, props.history));
        setPage(data.selected + 1);
    }

    const onResetHandler = () => {
        dispatch(globalLoadingAction(true));
        dispatch(approverApprovalReportDetails('', 1, perPage, props.history));
        setPage(1);
        setEmpId('');
    }

    const onChangeHandler = (index, value, parentIndex, childIndex) => {
        const updatedData = [...cardList];
        const time = value ? parseInt(value, 10) : 0; // Parse input as integer

        // Update the specific working hourF
        updatedData[index].data_table[parentIndex].time_details[childIndex].working_hour = time;

        // Calculate total hours for the current row
        const totalRowHours = updatedData[index].data_table[parentIndex].time_details.reduce(
            (acc, element) => acc + element.working_hour,
            0
        );
        updatedData[index].data_table[parentIndex].total_hour = totalRowHours;

        // Calculate the overall total hours
        const overallTotalHours = updatedData[index].data_table.reduce(
            (acc, row) => acc + row.total_hour,
            0
        );
        updatedData[index].total_hour = overallTotalHours;
        setCardList(updatedData);
    };

    const onChangeRateHandler = (index, value) => {
        const updatedData = [...cardList]; // Create a shallow copy of data
        const rate = value ? parseInt(value, 10) : 0;
        updatedData[index].emp_rate = rate;
        setCardList(updatedData);
    }

    const handleTabSelect = (key) => {
        setActiveTab(Number(key));
    };

    const onTimeDetails = (task_id, index, timeIndex, type) => {

        let date;
        if (type == 1) {
            date = cardNonList[index].time_table[timeIndex].date; // Example: "Jan 1"
        } else {
            date = cardList[index].time_table[timeIndex].date; // Example: "Jan 1"
        }

        let startDate = details.start_date; // "2024-12-02"
        let endDate = details.end_date; // "2025-01-01"

        // Extract years
        let startYear = startDate.split("-")[0]; // "2024"
        let endYear = endDate.split("-")[0]; // "2025"
        let startMonth = startDate.split("-")[1]; // "12" (December)
        // Month mapping
        const monthMap = {
            "Jan": "01",
            "Feb": "02",
            "Mar": "03",
            "Apr": "04",
            "May": "05",
            "Jun": "06",
            "Jul": "07",
            "Aug": "08",
            "Sep": "09",
            "Oct": "10",
            "Nov": "11",
            "Dec": "12",
        };

        // Split "Jan 1" into month and day
        let [monthAbbr, day] = date.split(" ");
        let month = monthMap[monthAbbr]; // Convert "Jan" -> "01"

        // Ensure day is always two digits (e.g., "01" instead of "1")
        day = day.padStart(2, "0");

        // Determine the correct year
        //let year = (month === "01") ? endYear : startYear; // Jan -> endYear, Dec -> startYear
        let year = (month < startMonth) ? endYear : startYear;

        // Construct final date string
        let output = `${year}-${month}-${day}`;

        dispatch(getreportDrillData(task_id, details.generate_by, output, 1, drill.perPage, trackCallBack, props.history));
        setDrill({ ...drill, date: output, projectId: task_id, generateBy: details.generate_by, page: 1 });
    };

    const trackCallBack = () => {
        setTimeModal(true);
    }

    const handlePageClick = (data) => {
        dispatch(getreportDrillData(drill.projectId, drill.generateBy, drill.date, data.selected + 1, drill.perPage, {}, props.history));
        setDrill({ ...drill, page: data.selected + 1 });
    };

    const tabData = [
        {
            id: 1,
            name: "Billable",
            content: <>{cardList.map((item, index) => {
                return (
                    <GenerateTimeCard
                        key={index}
                        getTimeDetails={(timeIndex, task_id) => onTimeDetails(task_id, index, timeIndex, 0)}
                        note={false}
                        isEdit={false}
                        isConfig={weeklyOff}
                        isLink={props.empType == 10 ? false : true}
                        details={item} />
                )
            })}</>,
        },
        {
            id: 2,
            name: "Non-billable",
            content: <>{cardNonList.map((item, index) => {
                return (
                    <GenerateTimeCard
                        key={index}
                        getTimeDetails={(timeIndex, task_id) => onTimeDetails(task_id, index, timeIndex, 1)}
                        note={false}
                        isEdit={false}
                        isConfig={weeklyOff}
                        isLink={props.empType == 10 ? false : true}
                        details={item} />
                )
            })}</>,
        }
    ];

    const onCommentHandler = (value) => {
        setTextComment(value);
        setIsComment(true);
    }

    return (
        <>
            <div className="row">

                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Report Approval</Link>
                        </li>
                        {/* <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Generated Report</Link>
                        </li> */}
                    </ul>
                </div>

                <div className="title-body-container">
                    <div className="title-body mb-3">
                        <h3>Report</h3>
                    </div>
                    <div className="title-filter-container">
                        <div className="form-group single-filter-select me-2">
                            <div className="select-drop-down">
                                <span><i class="fa fa-angle-down"></i></span>
                                <select
                                    defaultValue={"option"}
                                    className="form-control select-control"
                                    value={empId}
                                    onChange={(e) => onFilterEmpHandler(e.target.value)}
                                >
                                <option value="">Select {props.empType == 10 ? 'Reporter' : 'Employee'}</option>
                                    {
                                        props.employeeList.map((item, index) => {
                                            return <option key={index} value={item.user_id}>{item.first_name + " " + item.last_name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <button
                            className="reset_filter-btn me-0"
                            onClick={() => onResetHandler()}
                            disabled={!empId}
                        >Reset</button>
                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.timeCardDetails.length > 0 ?
                                            <>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <strong>Report Id</strong>
                                                            </th>
                                                            {props.empType != 10 ?
                                                                <th>
                                                                    <strong>Client Name</strong>
                                                                </th>
                                                                : null}
                                                            <th>
                                                                <strong>{props.empType == 10 ? 'Reporter Name' : 'Emp Name'}</strong>
                                                            </th>
                                                            {/* <th>
                                                                <strong>Project ID</strong>
                                                            </th> */}
                                                            {props.empType != 10 ?
                                                                <th>
                                                                    <strong>Report Type</strong>
                                                                </th> : null}
                                                            <th>
                                                                <strong>Date</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Comment</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Status</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Actions</strong>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            props.timeCardDetails.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.bill_id}</td>
                                                                        {props.empType != 10 ?
                                                                            <td>{item.client_first_name} {item.client_last_name}</td> : null}
                                                                        <td>{item.send_by_first_name} {item.send_by_last_name}</td>
                                                                        {/* <td>{item.project_id}</td> */}
                                                                        {props.empType != 10 ?
                                                                            <td>
                                                                                {(() => {
                                                                                    switch (item.track_type) {
                                                                                        case 1:
                                                                                            return "Billable"
                                                                                        case 2:
                                                                                            return "Non-billable"
                                                                                        default:
                                                                                            return "All"
                                                                                    }
                                                                                })()}
                                                                            </td> : null}
                                                                        <td><Moment format="D MMM YYYY" withTitle>{item.start_date}</Moment> to <Moment format="D MMM YYYY" withTitle>{item.end_date}</Moment></td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                disabled={item.send_back_msg ? false : true}
                                                                                onClick={() => onCommentHandler(item.send_back_msg)}
                                                                                className="btn btn-info shadow btn-xs sharp tooltip-comment"
                                                                            >
                                                                                <i className="fa fa-commenting" aria-hidden="true"></i>
                                                                            </button>
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                {(() => {
                                                                                    switch (item.status) {
                                                                                        case 3:
                                                                                            return (
                                                                                                <button className="btn btn-warning shadow btn-xs">
                                                                                                    Pending
                                                                                                </button>
                                                                                            );
                                                                                        case 5:
                                                                                            return (
                                                                                                <button className="btn btn-success shadow btn-xs">
                                                                                                    Approved
                                                                                                </button>
                                                                                            );
                                                                                        default:
                                                                                            return (
                                                                                                <button className="btn btn-secondary shadow btn-xs">
                                                                                                    Under Review
                                                                                                </button>
                                                                                            );
                                                                                    }
                                                                                })()}
                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <button
                                                                                    onClick={() => onViewHalder(item)}
                                                                                    className="btn btn-info shadow btn-xs"
                                                                                >Details
                                                                                </button>

                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>

                                            </>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Report data is empty or Try again search</p>
                                                <button type="button"
                                                //onClick={onCleanHandler}
                                                >
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                        <div className="pagination-container">
                                            {parseInt(props.totalRecord) > 0 && props.showLoading == false ? (
                                                <div className="page-result alignResult">
                                                    <p>Showing{" "}{(page - 1) * perPage + 1 + " - " + ((page - 1) * perPage + props.timeCardDetails.length) + " of " + "( " + props.totalRecord + " items )"}</p>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}

                                            {parseInt(props.totalRecord) > perPage ? (
                                                <ReactPaginate
                                                    containerClassName="pagination-gutter false pagination-circle pagination"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    breakLabel="..."
                                                    nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    renderOnZeroPageCount={null}
                                                    activeClassName="active"
                                                    onPageChange={handleCartPageClick}
                                                    pageRangeDisplayed={3}
                                                    pageCount={props.totalPage}
                                                    //initialPage={active - 1}
                                                    forcePage={page - 1}
                                                />
                                            ) : null}
                                        </div>
                                    </>
                                    : null}
                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>

            <Modal
                className="fade bd-example-modal-sm"
                size="xl"
                show={addModal}
                onHide={() => setAddModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h2>Time Report</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {cardList.length > 0 ?
                        <>
                            {/* {props.empType != 10 ? */}
                            <div className="card-detail-header">

                                <div className="card-detail-left">
                                    {/* <div>
                                        <h4 className='mb-2'><strong>Project ID:</strong> {details?.project_id}</h4>
                                    </div> */}
                                    <div>
                                        <h4 className='mb-2'><strong>Report ID:</strong> {details?.bill_id}</h4>
                                    </div>
                                    <div>
                                        <h4 className='mb-2'><strong>Date:</strong><Moment format="D MMM YYYY" withTitle>{details?.start_date}</Moment> to <Moment format="D MMM YYYY" withTitle>{details?.end_date}</Moment></h4>
                                    </div>

                                </div>
                                <div className="card-detail-right">

                                    <div>
                                        <h4 className='mb-2'><strong>Employee ID:</strong> {details?.employee_id}</h4>
                                    </div>
                                    {/* <div>
                                        <h4 className='mb-2'><strong>Employee Name:</strong> {details?.employee_first_name} {details?.employee_last_name}</h4>
                                    </div> */}
                                    <div>
                                        <h4 className='mb-2'><strong> {props.empType == 10 ? "Reporter Name" : "Employee Name"}:</strong> {details?.employee_first_name} {details?.employee_last_name}</h4>
                                    </div>
                                    {/* <div>
                                        <h4 className='mb-b'><strong>Employee Email:</strong> {details?.employee_email}</h4>
                                    </div> */}
                                </div>
                            </div>
                            {/* : null} */}
                            <div>
                                <ProgressReport legends={props.legends} step={props.stepCount - 2} />
                            </div>
                            {reportType == 0 ?
                                <div className='report-tabview'>
                                    <Tab.Container
                                        defaultActiveKey={activeTab}
                                        onSelect={handleTabSelect}
                                    >
                                        <Nav as="ul" className="nav-pills mb-4 light">
                                            {tabData.map((data, i) => (
                                                <Nav.Item as="li" key={i}>
                                                    <Nav.Link eventKey={data.id}>
                                                        {data.name}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                        <Tab.Content className="">
                                            {tabData.map((data, i) => (
                                                <Tab.Pane
                                                    eventKey={data.id}
                                                    key={i}
                                                >
                                                    {activeTab === data.id && data.content}
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Tab.Container>
                                </div> :
                                <>
                                    {cardList.map((item, index) => {
                                        return (
                                            <GenerateTimeCard
                                                key={index}
                                                // changeHandler={(value, childIndex, timesIndex) => onChangeHandler(index, value, childIndex, timesIndex)}
                                                // changeRateHandler={(value) => onChangeRateHandler(index, value)}
                                                getTimeDetails={(timeIndex, task_id) => onTimeDetails(task_id, index, timeIndex, 0)}
                                                note={false}
                                                isEdit={false}
                                                isConfig={weeklyOff}
                                                isLink={props.empType == 10 ? false : true}
                                                details={item} />
                                        )
                                    })}
                                </>}
                        </>
                        :
                        <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                </Modal.Body>
                <Modal.Footer>
                    {status == 3 ?
                        <>
                            <Button
                                variant="success"
                                onClick={() => onStatusHandler(4)}>
                                Approve {" "}
                                {loading && loadingStatus == 4 ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : null}
                            </Button>
                            <Button
                                variant="danger"
                                className='sendback_btn'
                                //onClick={() => onStatusHandler(2)}
                                onClick={() => setCommentModal(true)}
                            >
                                Send Back
                            </Button>
                        </> : null}

                    <Button variant="secondary" onClick={() => setAddModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                className="fade bd-example-modal-sm"
                size="md"
                show={commentModal}
                onHide={() => setCommentModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h4>Comment</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <textarea className='form-control' placeholder='Enter comment' value={comment} onChange={(e) => setComment(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        disabled={comment.length > 1 ? false : true}
                        onClick={() => onSendBackHandler(status - 1)}>
                        Submit {" "}
                        {loading && loadingStatus == 2 ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            : null}
                    </Button>
                    <Button variant="secondary" onClick={() => setCommentModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                className="fade bd-example-modal-sm"
                size="xl"
                show={timeModal}
                onHide={() => setTimeModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h2>Time Track Details</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.drillList.length > 0 ?
                        <>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>Client Name</strong>
                                        </th>
                                        <th>
                                            <strong>Employee Name</strong>
                                        </th>
                                        <th>
                                            <strong>Activity</strong>
                                        </th>
                                        <th>
                                            <strong>Task</strong>
                                        </th>
                                        <th>
                                            <strong>Duration</strong>
                                        </th>
                                        <th><strong>Date</strong></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.drillList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.client_first_name} {item.client_last_name}</td>
                                                    <td>{item.user_first_name} {item.user_last_name}</td>
                                                    <td>{item.activity}</td>
                                                    <td>{item.task}</td>
                                                    <td>{item.time_difference}</td>
                                                    <td><Moment format="D MMM YYYY" withTitle>{item.start_date}</Moment> to <Moment format="D MMM YYYY" withTitle>{item.end_date}</Moment></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <div className="nodata-found-view">
                            <h2>Data not found</h2>
                            <p>Report data is empty or Try again search</p>
                            <button type="button"
                            //onClick={onCleanHandler}
                            >
                                Retry
                            </button>
                        </div>
                    }
                    <div className="pagination-container">
                        {parseInt(props.drillTotalRecord) > 0 && props.showLoading == false ? (
                            <div className="page-result alignResult">
                                <p>Showing{" "}{(drill.page - 1) * drill.perPage + 1 + " - " + ((drill.page - 1) * drill.perPage + props.drillList.length) + " of " + "( " + props.drillTotalRecord + " items )"}</p>
                            </div>
                        ) : (
                            <div></div>
                        )}

                        {parseInt(props.drillTotalRecord) > drill.perPage ? (
                            <ReactPaginate
                                containerClassName="pagination-gutter false pagination-circle pagination"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                breakLabel="..."
                                nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                renderOnZeroPageCount={null}
                                activeClassName="active"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={props.drillTotalPage}
                                //initialPage={active - 1}
                                forcePage={drill.page - 1}
                            />
                        ) : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={() => setTimeModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                className="fade bd-example-modal-sm"
                size="sm"
                show={isComment}
            >
                <div className='comment-modal-container'>
                    <h4>Comment</h4>
                    <p>{textComment}</p>
                    <div className='comment-modal-btn'>
                        <button type="button"
                            onClick={() => setIsComment(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCard.screenVisible,
        showLoading: state.clients.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        countryList: state.country.countryList,
        timeCardDetails: state.timeCard.generatedDetails,
        totalPage: state.timeCard.totalPage,
        totalRecord: state.timeCard.totalRecord,
        configDetails: state.systemconfig.configDetails,
        systemAdmin: state.auth.systemAdmin,
        projectDetails: state.project.projectDetails,
        userId: state.auth.userId,
        empType: state.auth.empType,
        employeeList: state.employee.employeeList,
        drillList: state.timeCard.drillList,
        drillTotalPage: state.timeCard.drillTotalPage,
        drillTotalRecord: state.timeCard.drillTotalRecord,
        stepCount: state.timeCard.stepCount,
        legends: state.timeCard.legends,
    };
};
export default connect(mapStateToProps)(ClientApprover);