import React from "react";
import { Container, ProgressBar } from "react-bootstrap";
import moment from 'moment';
// const steps = [
//     { label: "Generated", icon: "bi bi-journal-check" },
//     { label: "Supervisor", icon: "bi bi-person-check" },
//     { label: "Reviewer", icon: "bi bi-box-seam" },
//     { label: "Approver", icon: "bi bi-shield-check" },
//     { label: "Client", icon: "bi bi-people" },
// ];



const ProgressReport = (props) => {

    const currentStep = props.step;
    const steps = props.legends;

    const getProgress = (step) => {
        let baseProgress = (step / (steps.length - 1)) * 100;
        let adjustmentMap = {}; // Declare outside to ensure it's accessible

        if (steps.length == 2) {
            adjustmentMap = { 0: 25, 1: 0 };
        } else if (steps.length == 3) {
            adjustmentMap = { 0: 18, 1: 0, 2: 5 };
        } else if (steps.length == 4) {
            adjustmentMap = { 0: 15, 1: 5, 2: -5, 3: 0 };
        } else if (steps.length == 5) {
            adjustmentMap = { 0: 10, 1: 5, 2: 0, 3: -5 };
        }

        const adjustment = adjustmentMap[step] || 0;
        return Math.min(Math.max(baseProgress + adjustment, 0), 100);
    };


    return (
        <div className="progress-report-container">
            <div className="progress-report">
                <div className="d-flex justify-content-between align-items-center position-relative mb-3">
                    {steps.map((step, index) => (
                        <div key={index} className="text-center flex-fill position-relative">

                            <div
                                className={`rounded-circle d-inline-flex align-items-center justify-content-center
                                 ${index <= currentStep ? "bg-warning text-white" : "bg-light text-dark"}`}
                                style={{
                                    width: "45px",
                                    height: "45px",
                                    border: "2px solid",
                                    borderColor: index <= currentStep ? "orange" : "gray",
                                    fontSize: "20px",
                                }}
                            >
                                <i className={step.icon}></i>
                            </div>
                            {/* Step Label */}
                            <div className="progress-text mt-2">
                                <p><strong>{step.title}</strong></p>
                                <p>{step.user_name}</p>
                                {step.approved_on ?<p>{moment(step.approved_on).format('DD MMM YY')}</p> : <p className="null-text">null</p>}
                            </div>
                            {/* Connecting Line */}
                            {index < steps.length - 1 && (
                                <div
                                    className="position-absolute top-50 start-100 translate-middle w-100"
                                    style={{
                                        height: "4px",
                                        backgroundColor: index < currentStep ? "orange" : "gray",
                                        zIndex: -1,
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <ProgressBar
                now={getProgress(currentStep)}
                variant="warning"
                className="mt-3"
                style={{ height: "8px" }}
            />

        </div>
    );
};

export default ProgressReport;
