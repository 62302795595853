import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion } from "react-bootstrap";
import moment from 'moment';


const GenerateTimeCard = (props) => {

    const [editable, setEditable] = useState(false);
    const [data, setData] = useState(null);

    // useEffect(() => {
    //     return () => {
    //       setEditable(false);
    //     };
    //   }, []);

    const handleDoubleClick = () => {
        setEditable(true);
        // if(props.isEdit){

        // }
    }

    const onChangeHandler = (value, index, childIndex) => {
        const updatedData = { ...data };
        const time = value ? parseInt(value, 10) : 0; // Parse input as integer

        // Update the specific working hour
        updatedData.data_table[index].time_details[childIndex].working_hour = time;

        // Calculate total hours for the current row
        const totalRowHours = updatedData.data_table[index].time_details.reduce(
            (acc, element) => acc + element.working_hour,
            0
        );
        updatedData.data_table[index].total_hour = totalRowHours;

        // Calculate the overall total hours
        const overallTotalHours = updatedData.data_table.reduce(
            (acc, row) => acc + row.total_hour,
            0
        );
        updatedData.total_hour = overallTotalHours;

        // Calculate the total pay based on employee rate and total hours
        // const overallTotalPay = updatedData.total_hour * (updatedData.emp_rate || 0);
        // updatedData.total_pay = overallTotalPay;

        // Update the state with the modified data
        setData(updatedData);
    };

    const onChangeRateHandler = (value) => {
        const updatedData = { ...data }; // Create a shallow copy of data
        const rate = value ? parseInt(value, 10) : 0;
        updatedData.emp_rate = rate;
        // Calculate the total pay based on employee rate and total hours
        // const overallTotalPay = updatedData.total_hour * (updatedData.emp_rate || 0);
        // updatedData.total_pay = overallTotalPay;
        setData(updatedData);
    }

    return (
        <Fragment>
            <Table className='cart-table table-bordered mb-3'>
                <thead>
                    <tr className='cart-table-header'>
                        <th colSpan={3}>
                            {props.type !== 1 && props.details?.user_name ? <strong>Name: {props.details?.user_name}</strong> : null}
                        </th>
                        {/* {props.details?.time_table?.length > 5 ?
                            <th>
                                <strong>Sun</strong>
                            </th> : null} */}
                        <th>
                            <strong>Sun</strong>
                        </th>
                        <th>
                            <strong>Mon</strong>
                        </th>
                        <th>
                            <strong>Tue</strong>
                        </th>
                        <th>
                            <strong>Wed</strong>
                        </th>
                        <th>
                            <strong>Thr</strong>
                        </th>
                        <th>
                            <strong>Fri</strong>
                        </th>
                        {/* {props.details?.time_table?.length > 5 ?
                            <th>
                                <strong>Sat</strong>
                            </th> : null} */}
                        <th>
                            <strong>Sat</strong>
                        </th>
                        <th className='text-center'>
                            <strong>Total Hrs</strong>
                        </th>

                    </tr>
                    <tr className='cart-table-header'>
                        <th>
                            <strong>Project</strong>
                        </th>

                        <th>
                            <strong>Activity</strong>
                        </th>
                        <th>
                            <strong>Task</strong>
                        </th>
                        {
                            props.details?.time_table?.length > 0 &&
                            props.details.time_table.slice(0, 7).map((dates, dateIndex) => (
                                <th key={dateIndex}>
                                    <strong>{dates.date}</strong>
                                </th>
                            ))
                        }
                        <th className='text-center'>
                            {props.isEdit ?
                                <button type="button" className="btn btn-warning btn-xs" onClick={() => handleDoubleClick()}><i class="fas fa-pencil-alt"></i></button> : null}

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.details?.data_table?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.project_id}</td>
                                    <td>{item.activity_id}</td>
                                    <td>{item.task_id}</td>
                                    {item.time_details?.slice(0, 7).map((times, timesIndex) => (
                                        <td
                                            className={`${props.isConfig && (timesIndex === 0 || timesIndex === 6) ? "disable-column" : ""}`}
                                            key={timesIndex}>
                                            {editable ?
                                                <>
                                                    {props.isConfig && (timesIndex === 0 || timesIndex === 6) ? null :
                                                        <input
                                                            className="timeEditable-control"
                                                            onChange={(e) => props.changeHandler(e.target.value, index, timesIndex)}
                                                            value={times.working_hour} />}
                                                </>
                                                :
                                                <>
                                                    {props.isConfig && (timesIndex === 0 || timesIndex === 6) ? '' :
                                                        <>
                                                            {props.isLink && times.working_hour > 0 ? (
                                                                <button className="time-link"
                                                                    onClick={() => props.getTimeDetails(timesIndex, item.task_id)}>
                                                                    {times.working_hour}
                                                                </button>
                                                            ) : (
                                                                times.working_hour
                                                            )}
                                                        </>}
                                                </>
                                            }
                                        </td>
                                    ))}
                                    <td className='total-hrs'>{item.total_hour}</td>

                                </tr>
                            );
                        })
                    }
                    <tr className='total-time'>
                        {/* {props.details?.time_table?.length > 5 ?
                            <>
                                <td></td>
                                <td></td>
                            </>
                            : null} */}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={2}><strong>TOTAL HOURS :</strong></td>
                        <td className='total-hrs'><strong>{props.details?.total_hour}</strong></td>
                    </tr>
                    {/* {props.type !== 1 ?
                        <tr className='total-time'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan={2}><strong>RATE PER HOURS :</strong></td>
                            <td className='total-hrs'>
                                {editable ?
                                    <>
                                        <storng>$</storng>
                                        <input
                                            className="timeEditable-control"
                                            onChange={(e) => props.changeRateHandler(e.target.value)}
                                            value={props.details?.emp_rate} />
                                    </>

                                    :
                                    <strong>${props.details?.emp_rate ? props.details.emp_rate : 0}</strong>
                                }
                            </td>
                        </tr>
                        : null}
                    <tr className='total-time'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={2}><strong>TOTAL PAY :</strong></td>
                        <td className='total-hrs'><strong>${Math.floor(props.details?.total_pay)}</strong></td>
                    </tr> */}

                </tbody>
            </Table>
        </Fragment>
    )
}

export default GenerateTimeCard;