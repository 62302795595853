import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function addTimeSheet(user_id, client_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateTimeSheet(user_id, time_track_id, client_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        time_track_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addOwnTimeSheet(user_id, client_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_OWN_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addUserTimeSheet(system_admin, user_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        system_admin,
        user_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_USER_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addOwnMultipleManualTimeSheet(timesheet) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        timesheet
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_OWN_BULK_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addUserMultipleManualTimeSheet(system_admin, timesheet) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        system_admin,
        timesheet
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_USER_BULK_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateOwnTimeSheet(user_id, time_track_id, client_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        time_track_id,
        client_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_OWN_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateUserTimeSheet(system_admin, user_id, time_track_id, project_id, activity_id, task_id, description, start_time, end_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        system_admin,
        user_id,
        time_track_id,
        project_id,
        activity_id,
        task_id,
        description,
        start_time,
        end_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_USER_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeTimeSheet(time_sheet_id, time_track_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        time_sheet_id,
        time_track_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getTimeCardList(searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TIME_CARD_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getTimeCardSingleData(user_id, filter_type, own_timesheet, client_id, project_id, month, year, start_date, end_date) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        filter_type,
        own_timesheet,
        client_id,
        project_id,
        month,
        year,
        start_date,
        end_date
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function generateTimesheet(user_id, filter_type, own_timesheet, client_id, project_id, month, year, start_date, end_date, track_type) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        filter_type,
        own_timesheet,
        client_id,
        project_id,
        month,
        year,
        start_date,
        end_date,
        track_type
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_GENERATE_REPORTS_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function generatedReport(page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        page_no,
        per_page
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_GENERATED_REPORTS_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function supervisorApprovalReport(emp_id, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        emp_id,
        page_no,
        per_page
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_APPROVE_REPORTS_DETAILS_FOR_SUPERVISOR_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function reviewerApprovalReport(emp_id, page_no, per_page) {
    const getData = localStorage.getItem('userDetails');
    const parsedData = JSON.parse(getData);
    const token = parsedData.token;
    const clientId = parsedData.client_id;
    const empType = parsedData.employee_type;

    const postData = {
        client_id: clientId,
        emp_id,
        page_no,
        per_page
    };

    const postData2 = {
        emp_id,
        page_no,
        per_page
    };

    const url =
        empType == 9
            ? GlobalConfigs.API_URL + Apis.GET_APPROVE_REPORTS_DETAILS_FOR_CLENT_REVIEWER_API
            : GlobalConfigs.API_URL + Apis.GET_APPROVE_REPORTS_DETAILS_FOR_REVIEWER_API;

    return axios({
        url: url,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        data: empType == 9 ? postData : postData2,
    });
}


export function approverApprovalReport(emp_id, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let clientId = JSON.parse(getData).client_id;
    let empType = JSON.parse(getData).employee_type;
    const postData = {
        client_id: clientId,
        emp_id,
        page_no,
        per_page
    };
    const postData2 = {
        emp_id,
        page_no,
        per_page
    };

    const url =
        empType == 10
            ? GlobalConfigs.API_URL + Apis.GET_APPROVE_REPORTS_DETAILS_FOR_CLIENT_APPROVER_API
            : GlobalConfigs.API_URL + Apis.GET_APPROVE_REPORTS_DETAILS_FOR_APPROVER_API;
    return axios({
        url: url,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: empType == 10 ? postData : postData2,
    });
}

export function generatedClientReport(client_id, emp_id, page_no, per_page,) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        client_id,
        emp_id,
        page_no,
        per_page
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_REPORTS_FOR_CLIENT_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateClientReportStatus(report_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        report_id,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_REPORTS_STATUS_FOR_CLIENT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}
export function reportSendBack(report_id, status,msg) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        report_id,
        status,
        msg
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REPORTS_SEND_BACK_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}
export function getClientTimeCard(filter_type, project_id, month, year, start_date, end_date) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        filter_type,
        project_id,
        month,
        year,
        start_date,
        end_date
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_CLIENT_TIME_CARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function postReportClientApproval(project_id, filter_type, month, year, start_date, end_date, bill_id, client_id, track_type, report_data, non_billable_report_data) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        filter_type,
        month,
        year,
        start_date,
        end_date,
        bill_id,
        client_id,
        track_type,
        report_data,
        non_billable_report_data,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_REPORTS_CLIENT_APPROVAL_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}


export function saveReportApproval(project_id, filter_type, month, year, start_date, end_date,track_type, report_data, non_billable_report_data) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        filter_type,
        month,
        year,
        start_date,
        end_date,
        track_type,
        report_data,
        non_billable_report_data,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_SAVE_REPORTS_APPROVAL_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getManualTimeSheet(filter_type, start_date, end_date, client_id, project_id, activity_id, task_id, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        filter_type,
        start_date,
        end_date,
        client_id,
        project_id,
        activity_id,
        task_id,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_MANUAL_TIMESHEET_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getReviewerTimeCardSingleData(user_id, project_id, filter_type, month, year, start_date, end_date) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_id,
        project_id,
        filter_type,
        month,
        year,
        start_date,
        end_date
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TIME_CARD_DETAILS_FOR_REVIEWER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}
export function getSuperVisorTimeCard() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SUPERVISOR_TIMECARD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        //data: postData,
    });
}

export function updateReport(report_id, report_data) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        report_id,
        report_data
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_UPDATE_REPORTS_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getreportDrill(task_id, generate_by, filter_date, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        task_id,
        generate_by,
        filter_date,
        page_no,
        per_page
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_REPORTS_DRILL_DOWN_WISE_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getPendingCount() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let clientId = JSON.parse(getData).client_id;
    let empType = JSON.parse(getData).employee_type;

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_REPORTS_PENDING_COUNT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: empType == 9 || empType == 10 ? { client_id: clientId } : {},
    });

}

export function getPeportProgress(report_id, project_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        report_id,
        project_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_REPORTS_PROGRESSBAR_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}


export function postReportRemove(report_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        report_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_REMOVE_REPORTS_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}