import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import swal from "sweetalert";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Tab, Nav, Spinner } from "react-bootstrap";
import Moment from 'react-moment';
import "react-datepicker/dist/react-datepicker.css";
import { updateReportDetails, generatedReportDetails, getreportDrillData, getPeportProgressData, postReportClientApprovalData, postReportRemoveData } from '../../../store/actions/TimeCardActions';
import { getNoteData, addNoteData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getSystemUserWeekOffData } from '../../../store/actions/SystemDefaultActions';
import GenerateTimeCard from "../Components/GenerateTimeCard";
import ProgressReport from "../Components/ProgressReport";
import ReactPaginate from "react-paginate";

const GeneratedReport = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [activeTab, setActiveTab] = useState(1);
    const [reportType, setReportType] = useState(null);
    const [cardList, setCardList] = useState([]);
    const [cardNonList, setCardNonList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [timeModal, setTimeModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [weeklyOff, setWeeklyOff] = useState(false);
    const [isComment, setIsComment] = useState(false);
    const [textComment, setTextComment] = useState('');

    //note
    const [message, setMessage] = useState('');
    const [projectId, setProjectId] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [status, setStatus] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);
    const [appLoading, setAppLoading] = useState(false);
    const [details, setDetails] = useState(null);
    //Drill day wise
    const [drill, setDrill] = useState({
        perPage: 10,
        page: 1,
        date: '',
        projectId: '',
        generateBy: ''
    });

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(generatedReportDetails(page, perPage, props.history));
        //dispatch(getSystemUserWeekOffData(3, props.systemAdmin, props.history));
    }, []);

    const handleCartPageClick = (data) => {
        dispatch(generatedReportDetails(data.selected + 1, perPage, props.history));
        setPage(data.selected + 1);
    }

    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }

    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onGetNoteHandler = (task_id) => {
        setProjectId(task_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(task_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onViewHalder = (item) => {
        // console.log('item..', item)
        // return
        if (item) {
            let array = JSON.parse(item.report_data);
            if (item.track_type == 0) {
                let array2 = JSON.parse(item.non_billable_report_data);
                setCardNonList(array2);
            }
            setReportId(item._id);
            setStatus(item.status);
            setCardList(array);
            setDetails(item);
            setReportType(item.track_type);
            setWeeklyOff(item.weekly_off);
            dispatch(getPeportProgressData(item._id, item.project_id, progressCallBack, props.history));
        }
    }

    const updateCallBack = () => {
        setReportId(null)
        setStatus(null);
        setAddModal(false);
        setLoading(false);
        setCardList([]);
        setIsSave(false);
    }

    const progressCallBack = () => {
        setAddModal(true);
    }

    const onUpdateHandler = () => {
        setLoading(true);
        dispatch(updateReportDetails(reportId, cardList, updateCallBack, props.history));
    }

    const onChangeHandler = (index, value, parentIndex, childIndex) => {
        const updatedData = [...cardList];
        const time = value ? parseInt(value, 10) : 0; // Parse input as integer

        // Update the specific working hourF
        updatedData[index].data_table[parentIndex].time_details[childIndex].working_hour = time;

        // Calculate total hours for the current row
        const totalRowHours = updatedData[index].data_table[parentIndex].time_details.reduce(
            (acc, element) => acc + element.working_hour,
            0
        );
        updatedData[index].data_table[parentIndex].total_hour = totalRowHours;

        // Calculate the overall total hours
        const overallTotalHours = updatedData[index].data_table.reduce(
            (acc, row) => acc + row.total_hour,
            0
        );
        updatedData[index].total_hour = overallTotalHours;
        // Calculate the total pay based on employee rate and total hours   
        const overallTotalPay = updatedData[index].total_hour * (updatedData[index].emp_rate || 0);
        updatedData[index].total_pay = overallTotalPay;
        setCardList(updatedData);
        setIsSave(true);
    };

    const onChangeRateHandler = (index, value) => {
        const updatedData = [...cardList];
        const rate = value ? parseInt(value) : 0;
        updatedData[index].emp_rate = rate;
        const overallTotalPay = updatedData[index].total_hour * (updatedData[index].emp_rate || 0);
        updatedData[index].total_pay = overallTotalPay;
        setCardList(updatedData);
    }

    const handleTabSelect = (key) => {
        setActiveTab(Number(key));
    };

    const tabData = [
        {
            id: 1,
            name: "Billable",
            content: <>{cardList.map((item, index) => {
                return (
                    <GenerateTimeCard
                        key={index}
                        changeHandler={(value, parentIndex, childIndex) => onChangeHandler(index, value, parentIndex, childIndex)}
                        // changeRateHandler={() => { }}
                        note={false}
                        isEdit={status == 0 ? true : false}
                        isConfig={weeklyOff}
                        isLink={true}
                        getTimeDetails={(timeIndex, task_id) => onTimeDetails(task_id, index, timeIndex, 0)}
                        details={item} />
                )
            })}</>,
        },
        {
            id: 2,
            name: "Non-billable",
            content: <>{cardNonList.map((item, index) => {
                return (
                    <GenerateTimeCard
                        key={index}
                        changeHandler={(value, parentIndex, childIndex) => onChangeHandler(index, value, parentIndex, childIndex)}
                        // changeRateHandler={() => { }}
                        note={false}
                        isEdit={status == 0 ? true : false}
                        isConfig={weeklyOff}
                        isLink={true}
                        getTimeDetails={(timeIndex, task_id) => onTimeDetails(task_id, index, timeIndex, 1)}
                        details={item} />
                )
            })}</>,
        }
    ];

    const onTimeDetails = (task_id, index, timeIndex, type) => {
        let date;
        if (type == 1) {
            date = cardNonList[index].time_table[timeIndex].date; // Example: "Jan 1"
        } else {
            date = cardList[index].time_table[timeIndex].date; // Example: "Jan 1"
        }
        let startDate = details.start_date; // "2024-12-02"
        let endDate = details.end_date; // "2025-01-01"

        // Extract years
        let startYear = startDate.split("-")[0]; // "2024"
        let endYear = endDate.split("-")[0]; // "2025"

        // Extract start month
        let startMonth = startDate.split("-")[1]; // "12" (December)
        // Month mapping
        const monthMap = {
            "Jan": "01",
            "Feb": "02",
            "Mar": "03",
            "Apr": "04",
            "May": "05",
            "Jun": "06",
            "Jul": "07",
            "Aug": "08",
            "Sep": "09",
            "Oct": "10",
            "Nov": "11",
            "Dec": "12",
        };

        // Split "Jan 1" into month and day
        let [monthAbbr, day] = date.split(" ");

        let month = monthMap[monthAbbr]; // Convert "Jan" -> "01"

        // Ensure day is always two digits (e.g., "01" instead of "1")
        day = day.padStart(2, "0");

        // Determine the correct year
        //let year = (month === "01") ? endYear : startYear; // Jan -> endYear, Dec -> startYear
        // Determine the correct year
        let year = (month < startMonth) ? endYear : startYear;
        // Construct final date string
        let output = `${year}-${month}-${day}`;
        dispatch(getreportDrillData(task_id, details.generate_by, output, 1, drill.perPage, trackCallBack, props.history));
        setDrill({ ...drill, date: output, projectId: task_id, generateBy: details.generate_by, page: 1 });
    };

    const trackCallBack = () => {
        setTimeModal(true);
    }

    const handlePageClick = (data) => {
        dispatch(getreportDrillData(drill.projectId, drill.generateBy, drill.date, data.selected + 1, drill.perPage, {}, props.history));
        setDrill({ ...drill, page: data.selected + 1 });
    };

    const onReportHandler = () => {
        setAppLoading(true);
        dispatch(postReportClientApprovalData(details?.project_table_id, 0, '', '', '', '', details?.bill_id, '', '', cardList, cardNonList, sendCallBack, props.history));
    }

    const sendCallBack = () => {
        setAddModal(false);
        dispatch(generatedReportDetails(page, perPage, props.history));
        setAppLoading(false);
    }

    const onCommentHandler = (value) => {
        setTextComment(value);
        setIsComment(true);
    }

      const onDeleteHandler = (id) => {
    
            swal({
                title: "Are you sure?",
                text:
                    "You want to remove this item !",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    dispatch(globalLoadingAction(true));
                    dispatch(postReportRemoveData(id, deleteCallBack));
                }
            })
        }

    const deleteCallBack = () => {
        dispatch(generatedReportDetails(page, perPage, props.history));
    }

    return (
        <>
            <div className="row">
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Generated Report</Link>
                        </li>
                    </ul>
                </div>

                <div className="title-body-container">
                    <div className="title-body mb-3">
                        <h3>Generated Report</h3>
                    </div>


                </div>

                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.timeCardDetails.length > 0 ?
                                            <>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <strong>Report Id</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Client Name</strong>
                                                            </th>
                                                            {/* <th>
                                                                <strong>Project ID</strong>
                                                            </th> */}
                                                            <th>
                                                                <strong>Report Type</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Date</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Comment</strong>
                                                            </th>
                                                            <th>
                                                                <strong>Status</strong>
                                                            </th>

                                                            <th>
                                                                <strong>Actions</strong>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    {/* {console.log('cardList.length..',props.timeCardDetails)} */}
                                                    <tbody>
                                                        {
                                                            props.timeCardDetails.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.bill_id}</td>
                                                                        <td>{item.client_first_name} {item.client_last_name}</td>
                                                                        {/* <td>{item.project_id}</td> */}
                                                                        <td>
                                                                            {(() => {
                                                                                switch (item.track_type) {
                                                                                    case 1:
                                                                                        return "Billable"
                                                                                    case 2:
                                                                                        return "Non-billable"
                                                                                    default:
                                                                                        return "All"
                                                                                }
                                                                            })()}
                                                                        </td>
                                                                        <td><Moment format="D MMM YYYY" withTitle>{item.start_date}</Moment> to <Moment format="D MMM YYYY" withTitle>{item.end_date}</Moment></td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                disabled={item.send_back_msg ? false : true}
                                                                                onClick={() => onCommentHandler(item.send_back_msg)}
                                                                                className="btn btn-info shadow btn-xs sharp tooltip-comment"
                                                                            >
                                                                                <i className="fa fa-commenting" aria-hidden="true"></i>
                                                                            </button>

                                                                        </td>
                                                                        <td>

                                                                            <div className="d-flex">
                                                                                {(() => {
                                                                                    switch (item.status) {
                                                                                        case 0:
                                                                                            return (
                                                                                                <button className="btn btn-warning shadow btn-xs">
                                                                                                    Pending
                                                                                                </button>
                                                                                            );
                                                                                        case 5:
                                                                                            return (
                                                                                                <button className="btn btn-success shadow btn-xs">
                                                                                                    Approved
                                                                                                </button>
                                                                                            );
                                                                                        default:
                                                                                            return (
                                                                                                <button className="btn btn-secondary shadow btn-xs">
                                                                                                    Under Review
                                                                                                </button>
                                                                                            );
                                                                                    }
                                                                                })()}
                                                                            </div>

                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <button
                                                                                    onClick={() => onViewHalder(item)}
                                                                                    className="btn btn-info shadow btn-xs"
                                                                                >Details
                                                                                </button>
                                                                                {item.status == 0 || item.status == 1 ?
                                                                                    <button
                                                                                        onClick={() => onDeleteHandler(item._id)}
                                                                                        className="btn btn-danger shadow btn-xs ms-2"
                                                                                    >Delete
                                                                                    </button> : null}
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Report data is empty or Try again search</p>
                                                <button type="button"
                                                //onClick={onCleanHandler}
                                                >
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                        <div className="pagination-container">
                                            {parseInt(props.totalRecord) > 0 && props.showLoading == false ? (
                                                <div className="page-result alignResult">
                                                    <p>Showing{" "}{(page - 1) * perPage + 1 + " - " + ((page - 1) * perPage + props.timeCardDetails.length) + " of " + "( " + props.totalRecord + " items )"}</p>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}

                                            {parseInt(props.totalRecord) > perPage ? (
                                                <ReactPaginate
                                                    containerClassName="pagination-gutter false pagination-circle pagination"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    breakLabel="..."
                                                    nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    renderOnZeroPageCount={null}
                                                    activeClassName="active"
                                                    onPageChange={handleCartPageClick}
                                                    pageRangeDisplayed={3}
                                                    pageCount={props.totalPage}
                                                    //initialPage={active - 1}
                                                    forcePage={page - 1}
                                                />
                                            ) : null}
                                        </div>
                                    </>
                                    : null}

                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>

            <Modal
                className="fade bd-example-modal-sm"
                size="xl"
                show={addModal}
                //onHide={() => setAddModal(false)}
                onHide={() => { isSave ? setSaveModal(true) : setAddModal(false) }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h2>Time Report</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {cardList.length > 0 ?
                        <>
                            <div className="card-detail-header">

                                <div className="card-detail-left">
                                    {/* <div>
                                        <h4 className='mb-2'><strong>Project ID:</strong> {details?.project_id}</h4>
                                    </div> */}
                                    <div>
                                        <h4 className='mb-2'><strong>Report ID:</strong> {details?.bill_id}</h4>
                                    </div>
                                    <div>
                                        <h4 className='mb-2'><strong>Date:</strong><Moment format="D MMM YYYY" withTitle>{details?.start_date}</Moment> to <Moment format="D MMM YYYY" withTitle>{details?.end_date}</Moment></h4>
                                    </div>

                                </div>
                                <div className="card-detail-right">
                                    <div>
                                        <h4 className='mb-2'><strong>Employee ID:</strong> {details?.employee_id}</h4>
                                    </div>
                                    <div>
                                        <h4 className='mb-2'><strong>Employee Name:</strong> {details?.employee_first_name} {details?.employee_last_name}</h4>
                                    </div>
                                    {/* <div>
                                        <h4 className='mb-b'><strong>Employee Email:</strong> {details?.employee_email}</h4>
                                    </div> */}
                                </div>

                            </div>
                            {console.log('props.legends..', props.legends)}
                            {status == 0 ? null :
                                <div>
                                    <ProgressReport legends={props.legends} step={props.stepCount - 2} />
                                </div>}
                            {reportType == 0 ?
                                <div className='report-tabview'>
                                    <Tab.Container
                                        defaultActiveKey={activeTab}
                                        onSelect={handleTabSelect}
                                    >
                                        <Nav as="ul" className="nav-pills mb-4 light">
                                            {tabData.map((data, i) => (
                                                <Nav.Item as="li" key={i}>
                                                    <Nav.Link eventKey={data.id}>
                                                        {data.name}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                        <Tab.Content className="">
                                            {tabData.map((data, i) => (
                                                <Tab.Pane
                                                    eventKey={data.id}
                                                    key={i}
                                                >
                                                    {activeTab === data.id && data.content}
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Tab.Container>
                                </div> :
                                <>
                                    {cardList.map((item, index) => {
                                        return (
                                            <GenerateTimeCard
                                                key={index}
                                                changeHandler={(value, childIndex, timesIndex) => onChangeHandler(index, value, childIndex, timesIndex)}
                                                // changeRateHandler={(value) => onChangeRateHandler(index, value)}
                                                note={false}
                                                isEdit={status == 0 ? true : false}
                                                isConfig={weeklyOff}
                                                isLink={true}
                                                getTimeDetails={(timeIndex, task_id) => onTimeDetails(task_id, index, timeIndex, 0)}
                                                details={item} />
                                        )
                                    })} </>}
                        </>
                        :
                        <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                </Modal.Body>
                <Modal.Footer>
                    {status == 0 ?
                        <>
                            <Button
                                variant="success"
                                onClick={() => isSave ? setSaveModal(true) : onReportHandler()}
                            >
                                Approval {" "}
                                {appLoading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : null}
                            </Button>
                            <Button
                                variant="warning"
                                onClick={() => onUpdateHandler()}>
                                Save {" "}
                                {loading ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : null}
                            </Button>
                        </>
                        : null}
                    <Button variant="secondary" onClick={() => { isSave ? setSaveModal(true) : setAddModal(false) }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                className="fade bd-example-modal-sm"
                size="md"
                show={saveModal}
                centered
            >
                <div className='expired-subcription-plan'>
                    <h3>Hey you are skipping the present report without saving it.</h3>
                    {/* <p>Please take a plan to continue using Clockmenow</p> */}

                    <button type="button"
                        onClick={() => setSaveModal(false)}
                        className="subscription-plan-btn">
                        Close
                    </button>

                </div>
            </Modal>

            <Modal
                className="fade bd-example-modal-sm"
                size="xl"
                show={timeModal}
                onHide={() => setTimeModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><h2>Time Track Details</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.drillList.length > 0 ?
                        <>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>Client Name</strong>
                                        </th>
                                        <th>
                                            <strong>Employee Name</strong>
                                        </th>
                                        <th>
                                            <strong>Activity</strong>
                                        </th>
                                        <th>
                                            <strong>Task</strong>
                                        </th>
                                        <th>
                                            <strong>Duration</strong>
                                        </th>
                                        <th><strong>Date</strong></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.drillList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.client_first_name} {item.client_last_name}</td>
                                                    <td>{item.user_first_name} {item.user_last_name}</td>
                                                    <td>{item.activity}</td>
                                                    <td>{item.task}</td>
                                                    <td>{item.time_difference}</td>
                                                    <td><Moment format="D MMM YYYY" withTitle>{item.start_date}</Moment> to <Moment format="D MMM YYYY" withTitle>{item.end_date}</Moment></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <div className="nodata-found-view">
                            <h2>Data not found</h2>
                            <p>Report data is empty or Try again search</p>
                            <button type="button"
                            //onClick={onCleanHandler}
                            >
                                Retry
                            </button>
                        </div>
                    }
                    <div className="pagination-container">
                        {parseInt(props.drillTotalRecord) > 0 && props.showLoading == false ? (
                            <div className="page-result alignResult">
                                <p>Showing{" "}{(drill.page - 1) * drill.perPage + 1 + " - " + ((drill.page - 1) * drill.perPage + props.drillList.length) + " of " + "( " + props.drillTotalRecord + " items )"}</p>
                            </div>
                        ) : (
                            <div></div>
                        )}

                        {parseInt(props.drillTotalRecord) > drill.perPage ? (
                            <ReactPaginate
                                containerClassName="pagination-gutter false pagination-circle pagination"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                breakLabel="..."
                                nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                renderOnZeroPageCount={null}
                                activeClassName="active"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={props.drillTotalPage}
                                //initialPage={active - 1}
                                forcePage={drill.page - 1}
                            />
                        ) : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={() => setTimeModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                className="fade bd-example-modal-sm"
                size="sm"
                show={isComment}
            >
                <div className='comment-modal-container'>
                    <h4>Comment</h4>
                    <p>{textComment}</p>
                    <div className='comment-modal-btn'>
                        <button type="button"
                            onClick={() => setIsComment(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCard.screenVisible,
        showLoading: state.clients.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        countryList: state.country.countryList,
        timeCardDetails: state.timeCard.generatedDetails,
        totalPage: state.timeCard.totalPage,
        totalRecord: state.timeCard.totalRecord,
        configDetails: state.systemconfig.weekConfig,
        systemAdmin: state.auth.systemAdmin,
        projectDetails: state.project.projectDetails,
        userId: state.auth.userId,
        employeeList: state.employee.projectEmployeeList,
        drillList: state.timeCard.drillList,
        drillTotalPage: state.timeCard.drillTotalPage,
        drillTotalRecord: state.timeCard.drillTotalRecord,
        stepCount: state.timeCard.stepCount,
        legends: state.timeCard.legends,
    };
};
export default connect(mapStateToProps)(GeneratedReport);