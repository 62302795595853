import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion, Tab, Nav } from "react-bootstrap";
import moment from 'moment';
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getEmployeeList, getEmployeeListByProjectId } from '../../../store/actions/EmployeeActions';
import { getTimeCardSingleDetails, addOwnTimeSheetData, updateOwnTimeSheetData, removeTimeSheetData, generateTimesheetDetails, postReportClientApprovalData, generateTimesheetAction, saveReportApprovalData } from '../../../store/actions/TimeCardActions';
import { sendToReviewerData, getNoteData, addNoteData } from '../../../store/actions/TimeCaptureActions';
import { getClientsData } from '../../../store/actions/ClientsActions';
import { getProjectList, getProjectByClientIdList, getProjectActivitiesListData, getAccessableTaskList, getProjectDetailsByIdData, getTaskList } from '../../../store/actions/ProjectActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getSystemUserConfigData, getSystemUserWeekOffData } from '../../../store/actions/SystemDefaultActions';
import DateRangeModal from '../Components/DateRangeModal';
import SheetReviewModal from '../Components/SheetReviewModal';
import ChatBox from "../Components/ChatBox";
import TimeCardView from "../Components/TimeCardView";
import UnbilableTab from '../../components/Components/UnbilableTab';
import BilableTab from '../../components/Components/BilableTab';
// import ViewPDF from '../Components/PDFViewer'
// import ErrorBoundary from '../Components/ErrorBoundary'

const ReportTo = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const filterRef = useRef();
    const filterRangeRef = useRef();

    let errorsObj = { client: '', project: '', activity: '', task: '', description: '', startDate: '', endDate: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [activeTab, setActiveTab] = useState(1);
    const [client, setClient] = useState('');
    const [project, setProject] = useState('');
    const [activity, setActivity] = useState('');
    const [task, setTask] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterType, setFilterType] = useState(0);
    const [addModal, setAddModal] = useState(false);
    const [action, setAction] = useState(1);
    const [record, setRecord] = useState(null);
    const [projectStartDate, setProjectStartDate] = useState('');
    const [projectEndDate, setProjectEndDate] = useState('');
    const [isDateRange, setIsDateRange] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [groupBy, setGroupBy] = useState(true);
    const [cardList, setCardList] = useState([]);
    const [billedCardList, setBilledCardList] = useState([]);
    const [unBilledCardList, setUnBilledCardList] = useState([]);
    const [reportModal, setReportModal] = useState(false);
    const [reportClient, setReportClient] = useState(false);

    //note
    const [message, setMessage] = useState('');
    const [projectId, setProjectId] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);
    const [weeklyOff, setWeeklyOff] = useState(false);

    //filter
    const [isFilter, setIsFilter] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [filterClient, setFilterClient] = useState('');
    const [filterProject, setFilterProject] = useState('');
    const [filterUser, setFilterUser] = useState(state.id);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterStartDateRange, setFilterStartDateRange] = useState('');
    const [filterEndDateRange, setFilterEndDateRange] = useState('');
    const [filterBilable, setFilterBilable] = useState('0');

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getSystemUserConfigData(2, props.systemAdmin, props.history));
        dispatch(getClientsData("", "", "", 1, 0, props.history));
        dispatch(getProjectList("", "", "", 1, 0, props.systemAdmin, props.history));
        dispatch(getSystemUserWeekOffData(3, props.systemAdmin, props.history));
        return () => {
            dispatch(generateTimesheetAction({}));
        }

    }, []);

    useEffect(() => {
        setFilterType(props?.configDetails?.filter_option_id);
    }, [props.configDetails]);


    // useEffect(() => {
    //     if (props.timeCardDetails?.data?.non_billable_report_data) {
    //         setUnBilledCardList(props.timeCardDetails.data.non_billable_report_data);
    //     }
    //     if (props.timeCardDetails?.data?.report_data) {
    //         setBilledCardList(props.timeCardDetails.data.report_data);
    //     }
    //     if (props.timeCardDetails?.data) {
    //         setCardList(props.timeCardDetails.data);
    //     }
    // }, [props.timeCardDetails]);

    useEffect(() => {
        const { data, weekly_off } = props.timeCardDetails || {};
        setWeeklyOff(weekly_off);
        if (data && typeof data === "object") {
            if (Array.isArray(data)) {
                // If data is an array
                setCardList(data);
            } else {
                // If data is an object
                if (data.non_billable_report_data) {
                    setUnBilledCardList(data.non_billable_report_data);
                }
                if (data.report_data) {
                    setBilledCardList(data.report_data);
                }
            }
        }
    }, [props.timeCardDetails]);

    useEffect(() => {
        if (props.projectDetails?.start_date) {
            setProjectStartDate(new Date(props.projectDetails.start_date));
            setProjectEndDate(new Date(props.projectDetails.end_date));
        }
    }, [props.projectDetails]);

    function onUpdateData(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (client === '') {
            errorObj.client = 'Client is Required';
            error = true;
        }
        if (project === '') {
            errorObj.project = 'Project is Required';
            error = true;
        }
        if (activity === '') {
            errorObj.activity = 'Activity is Required';
            error = true;
        }
        if (task === '') {
            errorObj.task = 'Task is Required';
            error = true;
        }
        if (startDate === '') {
            errorObj.startDate = 'Start date is Required';
            error = true;
        }
        if (endDate === '') {
            errorObj.endDate = 'End date is Required';
            error = true;
        }
        if (startDate >= projectStartDate && endDate >= projectEndDate) {
            setIsDateRange(true);
            error = true;
        }
        if (description === '') {
            errorObj.description = 'Description is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        if (action == 1) {
            setAddModal(false);
            setReviewModal(true);
        } else {
            // dispatch(globalLoadingAction(true));
            // dispatch(updateOwnTimeSheetData(
            //     state.id,
            //     record,
            //     client == "0" ? "" : client,
            //     project == "0" ? "" : project,
            //     activity == "0" ? "" : activity,
            //     task == "0" ? "" : task,
            //     description,
            //     startDate,
            //     endDate,
            //     filterType,
            //     true,
            //     filterClient ? filterClient : '',
            //     filterProject ? filterProject : '',
            //     filterType == 3 ? moment(filterStartDate).format('YYYY-MM-DD') : '',
            //     filterType == 3 ? moment(filterEndDate).format('YYYY-MM-DD') : '',
            //     groupBy,
            //     props.history));
            // setAddModal(false);
            // cleanHandler();
        }
    }

    const onFinalSubmitHandler = () => {
        // Get month and year based on filterStartDate or current date
        let month = filterStartDate ? moment(filterStartDate).format('M') : moment().format('M');
        let year = filterStartDate ? moment(filterStartDate).format('YYYY') : moment().format('YYYY'); // Full year

        // Dispatch loading action
        dispatch(globalLoadingAction(true));

        // Dispatch the action to add own timesheet data
        dispatch(addOwnTimeSheetData(
            state.id,
            client === "0" ? "" : client,
            project === "0" ? "" : project,
            activity === "0" ? "" : activity,
            task === "0" ? "" : task,
            description,
            startDate,
            endDate,
            filterType,
            filterType === 1 ? parseInt(month) : '',  // Send as integer for filterType 1
            filterType === 1 ? parseInt(year) : '',
            props.history
        ));

        // Reset modal and clear filters
        setReviewModal(false);
        onClearFilter();
        cleanHandler();
    };



    const onReviewCloseHandler = () => {
        setAddModal(true);
        setReviewModal(false);
    }

    const cleanHandler = () => {
        setClient('');
        setProject('');
        setActivity('');
        setTask('');
        setDescription('');
        setStartDate('');
        setEndDate('');
    }

    const onClientHandler = (val) => {
        setClient(val);
        setProject('');
        setActivity('');
        setTask('');
        dispatch(getProjectByClientIdList(val, props.history));
    }

    const onProjectHandler = (val) => {
        setProject(val);
        setActivity('');
        setTask('');
        if (val) {
            dispatch(getProjectDetailsByIdData(val, props.history));
            dispatch(getProjectActivitiesListData(val, '', '', '', 1, 0, props.systemAdmin, props.history));
        }
    }

    const onActivityHander = (val) => {
        setActivity(val);
        setTask('');
        dispatch(getAccessableTaskList(val, '', props.history));
    }

    const onFilterTypeHandler = (val) => {
        setFilterType(val);
    }

    const onAddHandler = () => {
        const errorObj = { ...errorsObj };
        setErrors(errorObj);
        setRecord('');
        setClient('');
        setProject('');
        setActivity('');
        setTask('');
        setStartDate('');
        setEndDate('');
        setDescription('');
        setAddModal(true);
        setAction(1);
    }

    const onEditHandler = (item) => {
        dispatch(getProjectDetailsByIdData(item.project_id, props.history));
        dispatch(getProjectByClientIdList(item.client_id, props.history));
        dispatch(getProjectActivitiesListData(item.project_id, '', '', '', 1, 0, props.systemAdmin, props.history));
        dispatch(getAccessableTaskList(item.activity_id, '', props.history));
        setRecord(item.time_track_id);
        setClient(item.client_id);
        setProject(item.project_id);
        setActivity(item.activity_id);
        setTask(item.task_id);
        setStartDate(new Date(item.start_time));
        setEndDate(new Date(item.end_time));
        setDescription(item.description);
        setProjectStartDate(new Date(item.project_start_date));
        setProjectEndDate(new Date(item.project_end_date));
        setErrors(errorsObj);
        setAddModal(true);
        setAction(2);
    }

    const onDeleteHandler = (item) => {
        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(globalLoadingAction(true));
                dispatch(removeTimeSheetData(item._id,
                    item.time_track_id,
                    state.id,
                    filterType,
                    true,
                    filterClient ? filterClient : '',
                    filterProject ? filterProject : '',
                    filterType == 3 ? moment(filterStartDate).format('YYYY-MM-DD') : '',
                    filterType == 3 ? moment(filterEndDate).format('YYYY-MM-DD') : '',
                    groupBy,
                    props.history));

            }
        })
    }

    const onFilterClientHandler = (val) => {
        setFilterClient(val);
        setFilterProject('');
        dispatch(getProjectByClientIdList(val, props.history));
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardSingleDetails(state.id, filterType, true,
            val, '',
            filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
    }

    const onFilterProjectHandler = (val) => {
        setFilterProject(val);
        setFilterUser('');
        // let month = filterStartDate ? moment(filterStartDate).format('M') : moment().format('M');
        // let year = filterStartDate ? moment(filterStartDate).format('YYYY') : moment().format('YYYY');
        // dispatch(globalLoadingAction(true));
        // dispatch(getTimeCardSingleDetails(
        //     state.id,
        //     filterType ? parseInt(filterType) : 0, 
        //     true,
        //     '',
        //     val,
        //     filterType === 1 ? parseInt(month) : '',
        //     filterType === 1 ? parseInt(year) : '',
        //     filterType === 3 && filterStartDateRange ? moment(filterStartDateRange).format('YYYY-MM-DD') : '',
        //     filterType === 3 && filterEndDateRange ? moment(filterEndDateRange).format('YYYY-MM-DD') : '',
        //     props.history
        // ));

    }

    const onFilterBillableHandler = (val) => {
        setFilterBilable(val);
        setFilterUser('');
    }

    const onFilterUserHandler = (val) => {
        setFilterUser(val);
        let month = filterStartDate ? moment(filterStartDate).format('M') : moment().format('M');
        let year = filterStartDate ? moment(filterStartDate).format('YYYY') : moment().format('YYYY');
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardSingleDetails(
            val,
            filterType ? parseInt(filterType) : 0, // Ensure filterType is parsed as an integer
            true,
            '',
            filterProject,
            filterType === 1 ? parseInt(month) : '', // Parse month if filterType is 1
            filterType === 1 ? parseInt(year) : '',  // Parse year if filterType is 1
            filterType === 3 && filterStartDateRange ? moment(filterStartDateRange).format('YYYY-MM-DD') : '',
            filterType === 3 && filterEndDateRange ? moment(filterEndDateRange).format('YYYY-MM-DD') : '',
            props.history
        ));

    }

    const onDateRangeFilterHandler = (dates) => {
        const [start, end] = dates;
        setFilterStartDateRange(start);
        setFilterEndDateRange(end);
        if (start && end) {
            // dispatch(globalLoadingAction(true));
            // dispatch(getTimeCardSingleDetails(
            //     state.id, 3, true, '', '', '', '',
            //     moment(start).format('YYYY-MM-DD'),
            //     moment(end).format('YYYY-MM-DD'),
            //     props.history
            // ));
            setFilterStartDate('');
            onClearFilter();
            setFilterType(3);
        }
    }

    const onChangeDateFilterHandler = (date) => {
        setFilterStartDate(date);
        if (date) {
            setFilterType(1);
        }
    }

    const formatStartDate = (date) => {
        return date ? date.toLocaleDateString() : ''; // Format the date as per your need
    };

    function onFilterSubmit(e) {
        e.preventDefault();
        dispatch(globalLoadingAction(false));
        dispatch(getTimeCardSingleDetails(state.id, filterType, true,
            filterClient, filterProject,
            filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
        setFilterModal(false);
        setIsFilter(true);
    }

    const onClearFilterHandler = () => {
        dispatch(globalLoadingAction(false));
        dispatch(getTimeCardSingleDetails(state.id,
            filterType,
            true, '', '',
            filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
        onClearFilter();
    }
    const onClearFilter = () => {
        setFilterModal(false);
        setFilterClient('');
        setFilterProject('');
        setFilterUser('');
        setIsFilter(false);
    }

    const onAssignHandler = (item) => {
        let array = [];
        item.data.forEach(element => {
            if (element.time_track_id) {
                array.push(element.time_track_id)
            }
        });
        dispatch(globalLoadingAction(true));
        dispatch(sendToReviewerData(item.project_id, array, sendToReviewerCallBack, props.history));
    }

    const sendToReviewerCallBack = () => {
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCardSingleDetails(state.id, filterType, true,
            filterClient, filterProject,
            filterType == 3 ? moment.utc(filterStartDate).format('YYYY-MM-DD') : '',
            filterType == 3 ? moment.utc(filterEndDate).format('YYYY-MM-DD') : '', groupBy, props.history));
    }

    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onGetNoteHandler = (task_id) => {
        setProjectId(task_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(task_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onReportHandler = () => {
        let month = filterStartDate ? moment(filterStartDate).format('M') : moment().format('M');
        let year = filterStartDate ? moment(filterStartDate).format('YYYY') : moment().format('YYYY');
        dispatch(globalLoadingAction(true));
        dispatch(postReportClientApprovalData(
            filterProject,
            filterType ? parseInt(filterType) : 0,
            filterType == 1 ? parseInt(month) : '',
            filterType == 1 ? parseInt(year) : '',
            filterType === 3 && filterStartDateRange ? moment(filterStartDateRange).format('YYYY-MM-DD') : '',
            filterType === 3 && filterEndDateRange ? moment(filterEndDateRange).format('YYYY-MM-DD') : '',
            props.timeCardDetails.bill_id,
            '',
            parseInt(filterBilable),
            filterBilable == 0 ? billedCardList : cardList,
            unBilledCardList,
            sendCallBack,
            props.history));
    }

    const onSaveHandler = () => {
        let month = filterStartDate ? moment(filterStartDate).format('M') : moment().format('M');
        let year = filterStartDate ? moment(filterStartDate).format('YYYY') : moment().format('YYYY');
        dispatch(globalLoadingAction(true));
        dispatch(saveReportApprovalData(
            filterProject,
            filterType ? parseInt(filterType) : 0,
            filterType == 1 ? parseInt(month) : '',
            filterType == 1 ? parseInt(year) : '',
            filterType === 3 && filterStartDateRange ? moment(filterStartDateRange).format('YYYY-MM-DD') : '',
            filterType === 3 && filterEndDateRange ? moment(filterEndDateRange).format('YYYY-MM-DD') : '',
            parseInt(filterBilable),
            filterBilable == 0 ? billedCardList : cardList,
            unBilledCardList,
            sendCallBack,
            props.history));
    }

    const onGenerateHandler = () => {
        let month = filterStartDate ? moment(filterStartDate).format('M') : moment().format('M');
        let year = filterStartDate ? moment(filterStartDate).format('YYYY') : moment().format('YYYY');
        dispatch(globalLoadingAction(true));
        dispatch(generateTimesheetDetails(
            state.id,
            filterType ? parseInt(filterType) : 0,
            true,
            '',
            filterProject,
            filterType == 1 ? parseInt(month) : '',
            filterType == 1 ? parseInt(year) : '',
            filterType === 3 && filterStartDateRange ? moment(filterStartDateRange).format('YYYY-MM-DD') : '',
            filterType === 3 && filterEndDateRange ? moment(filterEndDateRange).format('YYYY-MM-DD') : '',
            parseInt(filterBilable),
            props.history
        ));
    }

    function onReportSubmit(e) {
        e.preventDefault();
        let month = filterStartDate ? moment(filterStartDate).format('M') : moment().format('M');
        let year = filterStartDate ? moment(filterStartDate).format('YYYY') : moment().format('YYYY');
        dispatch(globalLoadingAction(true));
        dispatch(postReportClientApprovalData(
            filterProject,
            filterType ? parseInt(filterType) : 0,
            filterType == 1 ? parseInt(month) : '',
            filterType == 1 ? parseInt(year) : '',
            filterType === 3 && filterStartDateRange ? moment(filterStartDateRange).format('YYYY-MM-DD') : '',
            filterType === 3 && filterEndDateRange ? moment(filterEndDateRange).format('YYYY-MM-DD') : '',
            props.timeCardDetails.bill_id,
            reportClient,
            cardList,
            sendCallBack, props.history));

    }
    const sendCallBack = () => {
        setReportModal(false);
    }


    const tabData = [
        {
            id: 1,
            name: "Billable",
            content: <BilableTab
                data={billedCardList}
                config={weeklyOff}
                noteHandler={(id) => onGetNoteHandler(id)}
                reportHandler={() => onReportHandler()}
                saveHandler={() => onSaveHandler()}
            />,
        },
        {
            id: 2,
            name: "Non-billable",
            content: <UnbilableTab
                data={unBilledCardList}
                config={weeklyOff}
                noteHandler={(id) => onGetNoteHandler(id)}
                reportHandler={() => onReportHandler()}
                saveHandler={() => onSaveHandler()}
            />,
        }
    ];

    const handleTabSelect = (key) => {
        console.log("Selected Tab:", key);
        setActiveTab(Number(key)); // Ensure `key` is converted to a number
    };

    return (
        <>
            <div className="row">

                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Report To</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body mb-3">
                    <h3>{filterType == 1 ? 'Monthly' : filterType == 2 ? 'Weekly' : filterType == 3 ? 'Date wise' : null} Timesheet</h3>
                </div>
                <div className="title-body-container">
                    {/* <div className="title-body mb-3">
                        <h3>{filterType == 1 ? 'Monthly' : filterType == 2 ? 'Weekly' : filterType == 3 ? 'Date wise' : null} Timesheet</h3>
                    </div> */}
                    <div className="title-filter-container">
                        <div className="button-filter">
                            <button className="time-sheet-filter-btn" style={{ backgroundColor: filterType == 2 ? '#332d28' : '#7e7c7a' }} onClick={() => onFilterTypeHandler(2)} type='button'>Weekly</button>
                            <div className="time-sheet-filter-date-view">
                                <DatePicker
                                    ref={filterRef}
                                    selected={filterStartDate}
                                    showMonthYearPicker
                                    className="d-none"
                                    onChange={(date) => onChangeDateFilterHandler(date)}
                                    shouldCloseOnSelect={true}
                                    disabledKeyboardNavigation
                                />

                                <button
                                    type="button"
                                    className="time-sheet-filter-date-btn"
                                    style={{ backgroundColor: filterType === 1 ? '#332d28' : '#7e7c7a' }}
                                    onClick={() => filterRef.current.setOpen(true)} // Open datepicker on button click
                                >
                                    {filterStartDate && filterType === 1 ? `${moment(filterStartDate).format('MMMM YYYY')}` : 'Monthly'}

                                </button>
                            </div>

                            <div className="time-sheet-filter-date-view">
                                <DatePicker
                                    ref={filterRangeRef}
                                    selected={filterStartDateRange}
                                    className="d-none"
                                    selectsRange
                                    startDate={filterStartDateRange}
                                    endDate={filterEndDateRange}
                                    onChange={(dates) => onDateRangeFilterHandler(dates)}
                                />
                                <button
                                    type="button"
                                    className="time-sheet-filter-date-btn"
                                    style={{ backgroundColor: filterType === 3 ? '#332d28' : '#7e7c7a' }}
                                    onClick={() => filterRangeRef.current.setOpen(true)}
                                >
                                    {filterType === 3 && filterStartDateRange && filterEndDateRange
                                        ? `${moment(filterStartDateRange).format('DD/MM/YY')} - ${moment(filterEndDateRange).format('DD/MM/YY')}`
                                        : 'Date Range'}
                                </button>
                            </div>

                            <div className="form-group card-filter-select me-2">
                                <div className="select-drop-down">
                                    <span><i class="fa fa-angle-down"></i></span>
                                    <select
                                        defaultValue={"option"}
                                        className="form-control select-control"
                                        value={filterProject}
                                        onChange={(e) => onFilterProjectHandler(e.target.value)}>
                                        <option value="">Project</option>
                                        {
                                            props.projectList.map((item, index) => {
                                                return <option key={index} value={item._id}>{item.project_id}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group card-filter-select me-2">
                                <div className="select-drop-down">
                                    <span><i class="fa fa-angle-down"></i></span>
                                    <select
                                        defaultValue={"option"}
                                        className="form-control select-control"
                                        value={filterBilable}
                                        onChange={(e) => onFilterBillableHandler(e.target.value)}>
                                        <option value="0">Both</option>
                                        <option value="1">Billed</option>
                                        <option value="2">Unbilled</option>
                                    </select>
                                </div>
                            </div>
                            <button
                                className="generate-report-btn me-0"
                                onClick={() => onGenerateHandler()}
                                disabled={!filterProject}
                            >Generate Report</button>
                        </div>

                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>

                            <Card.Body>
                                {props.timeCardDetails?.data ?
                                    <>
                                        {filterBilable == 0 ?
                                            <div className='report-tabview'>
                                                <Tab.Container
                                                    defaultActiveKey={activeTab}
                                                    onSelect={handleTabSelect}
                                                >
                                                    <Nav as="ul" className="nav-pills mb-4 light">
                                                        {tabData.map((data, i) => (
                                                            <Nav.Item as="li" key={i}>
                                                                <Nav.Link eventKey={data.id}>
                                                                    {data.name}
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                    </Nav>
                                                    <Tab.Content className="">
                                                        {tabData.map((data, i) => (
                                                            <Tab.Pane
                                                                eventKey={data.id}
                                                                key={i}
                                                            >
                                                                {activeTab === data.id && data.content}
                                                            </Tab.Pane>
                                                        ))}
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div> :
                                            <>
                                                {cardList.length > 0 ?
                                                    <div className='report-header'>
                                                        <button
                                                            type='button'
                                                            className='me-2'
                                                            onClick={() => onReportHandler()}
                                                        >Approval</button>
                                                        <button
                                                            type='button'
                                                            onClick={() => onSaveHandler()}
                                                        >Save</button>
                                                    </div>
                                                    : null}
                                                {cardList.length > 0 ?
                                                    cardList.map((item, index) => {
                                                        return (
                                                            <TimeCardView
                                                                key={index}
                                                                onNoteHandler={(id) => onGetNoteHandler(id)}
                                                                isConfig={weeklyOff}
                                                                details={item} />
                                                        )
                                                    })
                                                    :
                                                    <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                                            </>
                                        }
                                    </>
                                    : <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                            </Card.Body>

                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>

            <Modal
                className="fade bd-example-modal-sm"
                size="lg"
                show={addModal}
                onHide={() => setAddModal(false)}
            >
                <div className="add-user-modal-form-view client-modal">
                    <button onClick={() => setAddModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="modal-title-view">
                        <h3>{action == 1 ? "Add" : "Update"} Timesheet</h3>
                    </div>
                    <div className="basic-form">
                        <form onSubmit={onUpdateData}>
                            <div className="row">

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Client</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={client}
                                            onChange={(e) => onClientHandler(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.clientList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.first_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {errors.client && <div className="text-danger fs-12">{errors.client}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Project</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={project}
                                            onChange={(e) => onProjectHandler(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.projectList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.project_id}</option>
                                                })
                                            }
                                        </select>
                                        {errors.project && <div className="text-danger fs-12">{errors.project}</div>}
                                    </div>
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Activity</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={activity}
                                            onChange={(e) => onActivityHander(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.activitiesList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.activity_id}</option>
                                                })
                                            }
                                        </select>
                                        {errors.activity && <div className="text-danger fs-12">{errors.activity}</div>}
                                    </div>
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Task</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={task}
                                            onChange={(e) => setTask(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.taskList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.task_id}</option>
                                                })
                                            }
                                        </select>
                                        {errors.task && <div className="text-danger fs-12">{errors.task}</div>}
                                    </div>
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>Start time</strong>
                                    </label>
                                    <div className="form-calendar">
                                        <DatePicker
                                            ref={startDateRef}
                                            selected={startDate}
                                            timeInputLabel="Time:"
                                            className="form-control"
                                            placeholderText=""
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                            onChange={(date) => setStartDate(date)}
                                        />
                                        <button type='button' className="form-calendar-btn"
                                            onClick={() => startDateRef.current.setOpen(true)}
                                        >
                                            <i class="fa-regular fa-calendar-days"></i>
                                        </button>
                                    </div>
                                    {errors.startDate && <div className="text-danger fs-12">{errors.startDate}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        <strong>End time</strong>
                                    </label>
                                    <div className="form-calendar">
                                        <DatePicker
                                            ref={endDateRef}
                                            selected={endDate}
                                            timeInputLabel="Time:"
                                            className="form-control"
                                            placeholderText=""
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                            onChange={(date) => setEndDate(date)}
                                        />
                                        <button type='button' className="form-calendar-btn"
                                            onClick={() => endDateRef.current.setOpen(true)}
                                        >
                                            <i class="fa-regular fa-calendar-days"></i>
                                        </button>
                                    </div>
                                    {errors.endDate && <div className="text-danger fs-12">{errors.endDate}</div>}
                                </div>

                                <div className="form-group mb-3 col-12">
                                    <label className="mb-1">
                                        <strong>Description</strong>
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows={2}
                                        //placeholder='Description'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)} />
                                    {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                                </div>

                            </div>

                            <div className="modal-button-group mt-4">
                                <button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary role-form-btn">
                                    {action == 1 ? "Add" : "Update"}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Modal>

            <Modal
                className="fade bd-example-modal-sm"
                size="md"
                show={filterModal}
                onHide={() => setFilterModal(false)}
            >
                <div className="add-user-modal-form-view">
                    <button onClick={() => setFilterModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="modal-title-view">
                        <h3>Filter</h3>
                    </div>
                    <div className="basic-form">
                        <form onSubmit={onFilterSubmit}>
                            <div className="row">

                                <div className="form-group form-group-modal-custom mb-3">
                                    <label className="mb-1">
                                        <strong>Client</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={filterClient}
                                            onChange={(e) => onFilterClientHandler(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.clientList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.first_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group form-group-modal-custom mb-3">
                                    <label className="mb-1">
                                        <strong>Project</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={filterProject}
                                            onChange={(e) => setFilterProject(e.target.value)}>
                                            <option value=""></option>
                                            {
                                                props.projectList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.project_id}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-button-group mt-4">
                                <button type="button" disabled={filterClient || filterProject ? false : true} onClick={() => onClearFilterHandler()} className="btn form-cencel-btn">
                                    Clear Filter
                                </button>
                                <button type="submit" disabled={filterClient || filterProject ? false : true} className="btn btn-primary role-form-btn">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Modal>

            <Modal
                className="fade bd-example-modal-sm"
                size="md"
                show={reportModal}
                onHide={() => setReportModal(false)}
            >
                <div className="add-user-modal-form-view">
                    <button onClick={() => setReportModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="modal-title-view">
                        <h3>Report To</h3>
                    </div>
                    <div className="basic-form">
                        <form onSubmit={onReportSubmit}>
                            <div className="row">

                                <div className="form-group form-group-modal-custom mb-3">
                                    <label className="mb-1">
                                        <strong>Client</strong>
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control select-control"
                                            value={reportClient}
                                            onChange={(e) => setReportClient(e.target.value)}>
                                            <option value="">Select Client</option>
                                            {
                                                props.clientList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.first_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-button-group mt-4">
                                <button type="submit" className="btn btn-primary role-form-btn">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Modal>

            <DateRangeModal
                show={isDateRange}
                onClose={() => setIsDateRange(false)}
            />
            <SheetReviewModal
                title={"Are you soure ?"}
                description={"you want to add time"}
                show={reviewModal}
                onClose={() => onReviewCloseHandler()}
                onSubmit={onFinalSubmitHandler}
            />
            <ChatBox
                show={isNote}
                data={noteData}
                value={message}
                onChangeHandler={(val) => setMessage(val)}
                onSubmitHandler={() => onSubmitMessageHandler()}
                closeHandler={closeHandler}
                loading={loading}
                currentUser={props.userId}
            />
            {/* <Modal
                className="fade bd-example-modal-sm"
                size="lg"
                show={false}
            >
              <ViewPDF data={props.details?.data} />
            </Modal> */}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.clients.screenVisible,
        showLoading: state.clients.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        countryList: state.country.countryList,
        timeCardDetails: state.timeCard.generateDetails,
        configDetails: state.systemconfig.configDetails,
        configWeeks: state.systemconfig.weekConfig,
        systemAdmin: state.auth.systemAdmin,
        projectDetails: state.project.projectDetails,
        userId: state.auth.userId,
        employeeList: state.employee.projectEmployeeList,
    };
};
export default connect(mapStateToProps)(ReportTo);