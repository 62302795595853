import swal from "sweetalert";
import {
    addTimeSheet,
    getTimeCardSingleData,
    getTimeCardList,
    addOwnTimeSheet,
    removeTimeSheet,
    updateOwnTimeSheet,
    updateTimeSheet,
    addUserTimeSheet,
    updateUserTimeSheet,
    getManualTimeSheet,
    addUserMultipleManualTimeSheet,
    addOwnMultipleManualTimeSheet,
    getReviewerTimeCardSingleData,
    getSuperVisorTimeCard,
    getClientTimeCard,
    generateTimesheet,
    postReportClientApproval,
    generatedReport,
    generatedClientReport,
    updateClientReportStatus,
    updateReport,
    supervisorApprovalReport,
    reviewerApprovalReport,
    approverApprovalReport,
    getreportDrill,
    getPendingCount,
    getPeportProgress,
    saveReportApproval,
    reportSendBack,
    postReportRemove
} from '../../services/TimeCardService';

import {
    LOADING_TIME_CARD,
    ADD_TIME_CARD,
    GET_TIME_CARD_LIST,
    GET_TIME_CARD_SINGLE_DETAILS,
    GET_MANUAL_TIMESHEET,
    GET_GENERATE_TIME_CARD_DETAILS,
    GET_GENERATED_REPORTS_DETAILS,
    GET_REPORT_DRILL_LIST,
    REPORT_PENDING_COUNT,
    REPORT_PROGRESS_STATUS
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function addTimeSheetData(user_id, client_id, project_id, activity_id, task_id, description, start_time, end_time, filter_type, month, year, history) {
    return (dispatch) => {
        addTimeSheet(user_id, client_id, project_id, activity_id, task_id, description, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetails(user_id, filter_type, false, '', '', month, year, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTimeSheetData(user_id, time_track_id, client_id, project_id, activity_id, task_id, description, start_time, end_time, filter_type, own_timesheet, filter_client, filter_project, filter_start_time, filter_end_time, group_by_project, history) {
    return (dispatch) => {
        updateTimeSheet(user_id, time_track_id, client_id, project_id, activity_id, task_id, description, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(getTimeCardSingleDetails(user_id, filter_type, own_timesheet, filter_client, filter_project, filter_start_time, filter_end_time, group_by_project, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addOwnTimeSheetData(user_id, client_id, project_id, activity_id, task_id, description, start_time, end_time, filter_type, month, year, history) {
    return (dispatch) => {
        addOwnTimeSheet(user_id, client_id, project_id, activity_id, task_id, description, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetails(user_id, filter_type, true, '', '', month, year, '', '', history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addUserTimeSheetData(systemAdmin, user_id, project_id, activity_id, task_id, description, start_time, end_time, filter_type, month, year, history) {
    return (dispatch) => {
        addUserTimeSheet(systemAdmin, user_id, project_id, activity_id, task_id, description, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetails(user_id, filter_type, true, '', '', month, year, history));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addManualTimeSheetData(systemAdmin, user_id, project_id, activity_id, task_id, description, start_time, end_time, perPage, history) {
    return (dispatch) => {
        addUserTimeSheet(systemAdmin, user_id, project_id, activity_id, task_id, description, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getManualTimeSheetData(0, '', '', '', '', '', '', 1, perPage, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addOwnMultipleManualTimeSheetData(timesheet, perPage, history) {
    return (dispatch) => {
        addOwnMultipleManualTimeSheet(timesheet)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getManualTimeSheetData(0, '', '', '', '', '', '', 1, perPage, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addUserMultipleManualTimeSheetData(system_admin, timesheet, perPage, history) {
    return (dispatch) => {
        addUserMultipleManualTimeSheet(system_admin, timesheet)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getManualTimeSheetData(0, '', '', '', '', '', '', 1, perPage, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateManualTimeSheetData(systemAdmin, user_id, time_track_id, project_id, activity_id, task_id, description, start_time, end_time, obj, history) {
    return (dispatch) => {
        updateUserTimeSheet(systemAdmin, user_id, time_track_id, project_id, activity_id, task_id, description, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getManualTimeSheetData(obj.filterType, obj.filterStartDate, obj.filterEndtDate, obj.filterClient, obj.filterProject, obj.filterActivity, obj.filterTask, obj.page, obj.perPage, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateOwnTimeSheetData(user_id, time_track_id, client_id, project_id, activity_id, task_id, description, start_time, end_time, filter_type, own_timesheet, filter_client, filter_project, filter_start_time, filter_end_time, groupBy, history) {
    return (dispatch) => {
        updateOwnTimeSheet(user_id, time_track_id, client_id, project_id, activity_id, task_id, description, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetails(user_id, filter_type, own_timesheet, filter_client, filter_project, filter_start_time, filter_end_time, groupBy, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateUserTimeSheetData(user_id, time_track_id, project_id, activity_id, task_id, description, start_time, end_time, filter_type, own_timesheet, filter_project, filter_start_time, filter_end_time, groupBy, history) {
    return (dispatch) => {
        updateUserTimeSheet(user_id, time_track_id, project_id, activity_id, task_id, description, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetails(user_id, filter_type, own_timesheet, '', filter_project, filter_start_time, filter_end_time, groupBy, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeTimeSheetData(id, time_track_id, user_id, filter_type, own_timesheet, filter_client, filter_project, filter_start_time, filter_end_time, groupBy, history) {
    return (dispatch) => {
        removeTimeSheet(id, time_track_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetails(user_id, filter_type, own_timesheet, filter_client, filter_project, filter_start_time, filter_end_time, groupBy, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}
export function getTimeCardListData(searchString, orderByField, orderByFieldValue, page_no, per_page, history) {
    return (dispatch) => {
        getTimeCardList(searchString, orderByField, orderByFieldValue, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardListAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getTimeCardSingleDetails(user_id, filter_type, own_timesheet, client_id, project_id, month, year, start_date, end_date, history) {
    return (dispatch) => {
        getTimeCardSingleData(user_id, filter_type, own_timesheet, client_id, project_id, month, year, start_date, end_date)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetailsAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function generateTimesheetDetails(user_id, filter_type, own_timesheet, client_id, project_id, month, year, start_date, end_date, track_type, history) {
    return (dispatch) => {
        generateTimesheet(user_id, filter_type, own_timesheet, client_id, project_id, month, year, start_date, end_date, track_type)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(generateTimesheetAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function generatedReportDetails(page_no, per_page, history) {
    return (dispatch) => {
        generatedReport(page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(generatedReportAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function supervisorApprovalReportDetails(emp_id, page_no, per_page, history) {
    return (dispatch) => {
        supervisorApprovalReport(emp_id, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(generatedReportAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function reviewerApprovalReportDetails(emp_id, page_no, per_page, history) {
    return (dispatch) => {
        reviewerApprovalReport(emp_id, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(generatedReportAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function approverApprovalReportDetails(emp_id, page_no, per_page, history) {
    return (dispatch) => {
        approverApprovalReport(emp_id, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(generatedReportAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateReportDetails(report_id, report_data, callBack, history) {
    return (dispatch) => {
        updateReport(report_id, report_data)
            .then((response) => {
                if (response.data.status == 200) {
                    callBack();
                    dispatch(generatedReportDetails(history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function generatedClientReportDetails(client_id, emp_id, page_no, per_page, history) {
    return (dispatch) => {
        generatedClientReport(client_id, emp_id, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(generatedReportAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateClientReportStatusDetails(report_id, status, callBack, history) {
    return (dispatch) => {
        updateClientReportStatus(report_id, status)
            .then((response) => {
                if (response.data.status == 200) {
                    callBack();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function reportSendBackDetails(report_id, status, msg, callBack, history) {
    return (dispatch) => {
        reportSendBack(report_id, status, msg)
            .then((response) => {
                if (response.data.status == 200) {
                    callBack();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getClientTimeCardDetails(filter_type, project_id, month, year, start_date, end_date, history) {
    return (dispatch) => {
        getClientTimeCard(filter_type, project_id, month, year, start_date, end_date)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetailsAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function postReportClientApprovalData(project_id, filter_type, month, year, start_date, end_date, bill_id, client_id, track_type, report_data, non_billable_report_data, callBack, history) {
    return (dispatch) => {
        postReportClientApproval(project_id, filter_type, month, year, start_date, end_date, bill_id, client_id, track_type, report_data, non_billable_report_data)
            .then((response) => {
                if (response.data.status == 200) {
                    callBack();
                    utility.showSuccess(response.data.msg);
                    history.push('/generated-report');
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function saveReportApprovalData(project_id, filter_type, month, year, start_date, end_date, track_type, report_data, non_billable_report_data, callBack, history) {
    return (dispatch) => {
        saveReportApproval(project_id, filter_type, month, year, start_date, end_date, track_type, report_data, non_billable_report_data)
            .then((response) => {
                if (response.data.status == 200) {
                    callBack();
                    utility.showSuccess(response.data.msg);
                    history.push('/generated-report');
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getSuperVisorTimeCardDetails(history) {
    return (dispatch) => {
        getSuperVisorTimeCard()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetailsAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getReviewerTimeCardSingleDetails(user_id, project_id, filter_type, month, year, start_date, end_date, history) {
    return (dispatch) => {
        getReviewerTimeCardSingleData(user_id, project_id, filter_type, month, year, start_date, end_date)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCardSingleDetailsAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getManualTimeSheetData(filter_type, start_date, end_date, client_id, project_id, activity_id, task_id, page_no, per_page, history) {
    return (dispatch) => {
        getManualTimeSheet(filter_type, start_date, end_date, client_id, project_id, activity_id, task_id, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getManualTimeSheetAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getreportDrillData(task_id, generate_by, filter_date, page_no, per_page, trackCallBack, history) {
    return (dispatch) => {
        getreportDrill(task_id, generate_by, filter_date, page_no, per_page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getreportDrillAction(response.data));
                    trackCallBack();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getPendingCountData(history) {
    return (dispatch) => {
        getPendingCount()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getPendingCountAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getPeportProgressData(report_id, project_id, callBack, history) {
    return (dispatch) => {
        getPeportProgress(report_id, project_id)
            .then((response) => {
                if (response.data.status == 200) {
                    callBack();
                    dispatch(getPeportProgressAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function postReportRemoveData(report_id,callBack) {
    return (dispatch) => {
        postReportRemove(report_id)
            .then((response) => {
                if (response.data.status == 200) {
                    callBack();
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addTimeAction(data) {
    return {
        type: ADD_TIME_CARD,
        payload: data,
    };
}

export function getTimeCardListAction(data) {
    return {
        type: GET_TIME_CARD_LIST,
        payload: data,
    };
}

export function getTimeCardSingleDetailsAction(data) {
    return {
        type: GET_TIME_CARD_SINGLE_DETAILS,
        payload: data,
    };
}

export function generateTimesheetAction(data) {
    return {
        type: GET_GENERATE_TIME_CARD_DETAILS,
        payload: data,
    };
}

export function generatedReportAction(data) {
    return {
        type: GET_GENERATED_REPORTS_DETAILS,
        payload: data,
    };
}

export function getManualTimeSheetAction(data) {
    return {
        type: GET_MANUAL_TIMESHEET,
        payload: data,
    };
}
export function getreportDrillAction(data) {
    return {
        type: GET_REPORT_DRILL_LIST,
        payload: data,
    };
}
export function getPendingCountAction(data) {
    return {
        type: REPORT_PENDING_COUNT,
        payload: data,
    };
}
export function getPeportProgressAction(data) {
    return {
        type: REPORT_PROGRESS_STATUS,
        payload: data,
    };
}
export function loadingTimeCardAction(status) {
    return {
        type: LOADING_TIME_CARD,
        payload: status,
    };
}
