import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    ProgressBar,
} from "react-bootstrap";
import Moment from 'react-moment';
import swal from "sweetalert";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import AutoComplete from '../Components/AutoComplete';
import { getCountryList, getStateList, getCityList, countryLoadingAction } from '../../../store/actions/CountryAction';
import { addLocationData, updateLocationData, getLocationList, updateLocationStatusData, removeLocationData, downloadLocationData, removeMultipleLocationData, getLocationTypeData } from '../../../store/actions/LocationActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const type = ['Billing', 'Receiving', 'Office address', 'Remittance'];


const Location = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const wrapperRef = useRef(null);

    let errorsObj = { locId: '', locationType: '', address: '', country: '', state: '', city: '', postalCode: '', status: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [locId, setLocId] = useState('');
    const [locationType, setLocationType] = useState('');
    const [address, setAddress] = useState('');
    const [addressTwo, setAddressTwo] = useState('');
    const [country, setCountry] = useState('');
    const [countryId, setCountryId] = useState('');
    const [state, setState] = useState('');
    const [stateId, setStateId] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [status, setStatus] = useState('');
    const [action, setAction] = useState(1);
    const [locationId, setLocationId] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [checkArray, setCheckArray] = useState([]);
    const [isType, setIsType] = useState(false);
    const [typeList, setTypeList] = useState([]);
    const [addModal, setAddModal] = useState(false);

    const chackbox = document.querySelectorAll(".checkbox-select input");
    const motherChackBox = document.querySelector("#checkAll");

    //filter
    const [searchText, setSearchText] = useState('');
    const [sordBy, setSordBy] = useState('');
    const [sort, setSort] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getCountryList(props.history));
        dispatch(getLocationList('', '', '', 1, perPage, props.history));
        dispatch(getLocationTypeData(props.history));
    }, []);


    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsType(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    function onSubmit(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (address === '') {
            errorObj.address = 'Address is Required';
            error = true;
        }
        if (country === '') {
            errorObj.country = 'Country is Required';
            error = true;
        }
        if (state === '') {
            errorObj.state = 'State is Required';
            error = true;
        }
        if (city === '') {
            errorObj.city = 'City is Required';
            error = true;
        }
        if (postalCode === '') {
            errorObj.postalCode = 'Postal Code is Required';
            error = true;
        }
        if (status === '') {
            errorObj.status = 'Status is Required';
            error = true;
        }
        if (locId === '') {
            errorObj.locId = 'Location id is Required';
            error = true;
        }
        if (locationType === '') {
            errorObj.locationType = 'Location type is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        if (action == 1) {
            dispatch(addLocationData(
                locId,
                locationType,
                address,
                addressTwo,
                country,
                countryId,
                state,
                stateId,
                city,
                postalCode,
                parseInt(status),
                perPage,
                props.history));
            setSearchText('');
            setSordBy('');
            setSort(null);
            setPageNo(1);
        } else {
            dispatch(updateLocationData(locationId,
                locId,
                locationType, address,
                addressTwo, country, countryId,
                state, stateId, city, postalCode,
                parseInt(status),
                searchText,
                sordBy,
                sort === null ? sort ? 'asc' : 'desc' : '',
                pageNo,
                perPage,
                props.history));
        }
        setAction(1);
        setAddress('');
        setAddressTwo('');
        setCountry('');
        setCountryId('');
        setState('');
        setStateId('');
        setCity('');
        setPostalCode('');
        setStatus('');
        setLocId('');
        setLocationType('');
        setAddModal(false);
    }

    const onAddHandler = () => {
        const errorObj = { ...errorsObj };
        errorObj.locId = '';
        errorObj.locationType = '';
        errorObj.address = '';
        errorObj.country = '';
        errorObj.state = '';
        errorObj.city = '';
        errorObj.postalCode = '';
        errorObj.status = '';
        setErrors(errorObj);
        setAddModal(true);
        setAction(1);
        setAddress('');
        setAddressTwo('');
        setCountry('');
        setCountryId('');
        setState('');
        setStateId('');
        setCity('');
        setPostalCode('');
        setStatus('');
        setLocId('');
        setLocationType('');
        setIsDisabled(true);
        setCheckArray([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
        motherChackBox.checked = false;
    }

    const onEditHandler = (item) => {
        dispatch(getStateList(parseInt(item.country_id), props.history));
        dispatch(getCityList(parseInt(item.state_id), props.history));
        const errorObj = { ...errorsObj };
        errorObj.locId = '';
        errorObj.locationType = '';
        errorObj.address = '';
        errorObj.country = '';
        errorObj.state = '';
        errorObj.city = '';
        errorObj.postalCode = '';
        errorObj.status = '';
        setErrors(errorObj);
        setAddModal(true);
        setAction(2);
        setLocationId(item._id);
        setLocId(item.loc_id);
        setLocationType(item.loc_type);
        setAddress(item.address_1);
        setAddressTwo(item.address_2);
        setCountry(item.country);
        setCountryId(item.country_id);
        setState(item.state);
        setStateId(item.state_id);
        setCity(item.city);
        setPostalCode(item.postal_code);
        setStatus(item.status);
        setIsDisabled(true);
        setCheckArray([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
        motherChackBox.checked = false;
    }

    const updatedCountries = props.countryList.map((country) => ({
        label: country.name,
        value: country.name,
        ...country
    }));

    const onChangecountry = (option) => {
        setCountry(option.name);
        setCountryId(option.id);
        dispatch(getStateList(parseInt(option.id), props.history));
    }

    const updatedStates = props.stateList.map((state) => ({
        label: state.name,
        value: state.name,
        ...state
    }));

    const onChangeState = (option) => {
        setState(option.name);
        setStateId(option.id);
        dispatch(getCityList(parseInt(option.id), props.history));
    }

    const updatedCitys = props.cityList.map((city) => ({
        label: city.name,
        value: city.name,
        ...city
    }));

    const onChangeCity = (option) => {
        setCity(option.name);
    }

    const onStatusHandler = (item) => {
        dispatch(globalLoadingAction(true));
        dispatch(updateLocationStatusData(item));
    }

    const onDeleteHandler = (id) => {

        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                //dispatch(globalLoadingAction(true));
                if (props.list.length == 1) {
                    dispatch(removeLocationData(id, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage, props.history));
                    setPageNo(pageNo - 1);
                } else {
                    dispatch(removeLocationData(id, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage, props.history));
                }
            }
        });

    }

    const onMultipleRemoveHandler = () => {
        swal({
            title: "Are you sure?",
            text: checkArray.length > 1 ? "You want to remove items!" : "You want to remove item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(globalLoadingAction(true));
                if (props.list.length == checkArray.length) {
                    dispatch(removeMultipleLocationData(checkArray, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage, props.history));
                    setPageNo(pageNo - 1);
                } else {
                    dispatch(removeMultipleLocationData(checkArray, searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage, props.history));
                }
                setIsDisabled(true);
                setCheckArray([]);
                chackbox.forEach((val, index) => {
                    chackbox[index].checked = false;
                });
                motherChackBox.checked = false;
            }
        })
    }

    const chackboxFun = (element, item) => {

        let array = [...checkArray];
        if (item === 0) {
            if (motherChackBox.checked == true) {
                props.list.forEach((val, index) => {
                    array.push(val._id);
                    document.querySelector(`#check${index}`).checked = true;
                });
                setIsDisabled(false);
            } else {
                props.list.forEach((val, index) => {
                    array = [];
                    document.querySelector(`#check${index}`).checked = false;
                });
                setIsDisabled(true);
            }

        } else {

            if (element.target.checked == true) {
                for (let x = 0; chackbox.length > x; x++) {
                    if (document.querySelector(`#check${x}`).checked == false) {
                        motherChackBox.checked = false;
                        break;
                    } else {
                        motherChackBox.checked = true;
                        setIsDisabled(false);
                    }
                }
                setIsDisabled(false);
                array.push(item._id);
            } else {
                const index = array.findIndex((val) => val === item.id);
                array.splice(index, 1);
                motherChackBox.checked = false;
                for (let x = 0; chackbox.length > x; x++) {
                    if (document.querySelector(`#check${x}`).checked == true) {
                        setIsDisabled(false);
                        break;
                    } else {
                        setIsDisabled(true);
                    }
                }
            }
        }
        setCheckArray(array);
    };


    const onDownloadHandler = () => {
        dispatch(globalLoadingAction(true));
        dispatch(downloadLocationData(checkArray, props.history));
        setIsDisabled(true);
        setCheckArray([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
        motherChackBox.checked = false;
    }

    const locationTypeHanler = (item) => {
        setLocationType(item);
        setIsType(false);
    }

    const onSearchHandler = (val) => {
        setSearchText(val);
        setSort(null);
        setSordBy('');
        const timerId = setTimeout(() => {
            dispatch(globalLoadingAction(true));
            dispatch(getLocationList(val, '', '', 1, perPage, props.history));
        }, 1000);
        return () => clearTimeout(timerId); // Cleanup on component unmount or re-render
    }

    const onSordByHandler = (val) => {
        setSordBy(val);
        setSort(null);
    }

    const onSordHandler = () => {
        if (sordBy) {
            setSort(!sort);
            dispatch(globalLoadingAction(true));
            dispatch(getLocationList(searchText, sordBy, !sort ? 'asc' : 'desc', 1, perPage, props.history));
        }
    }

    const handlePageClick = (data) => {
        setPageNo(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getLocationList(searchText, '', '', data.selected + 1, perPage, props.history));
        setSort(null);
        setSordBy('');
    };

    const onCleanHandler = () => {
        setSearchText('');
        setPageNo(1);
        setSort(null);
        setSordBy('');
        dispatch(globalLoadingAction(true));
        dispatch(getLocationList('', '', '', 1, perPage, props.history));
    }

    return (
        <>
            {console.log('props.list...', props.list)}
            <div className="row">
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>System Setting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Location</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body-container">
                    <div className="title-body">
                        <h3>Location</h3>
                        <p>Maintain user information - Add, edit, delete and invite users</p>
                    </div>
                    <div className="title-filter-container">

                        <div className="search-filter">
                            <input placeholder="Search" value={searchText} onChange={(e) => onSearchHandler(e.target.value)} />
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>

                        <div className="sort-filter">
                            <select
                                defaultValue={"option"}
                                className=""
                                value={sordBy}
                                onChange={(e) => onSordByHandler(e.target.value)}>
                                <option>Sort by</option>
                                <option value="loc_type">Type</option>
                                <option value="address_1">Address</option>
                                <option value="country">Country</option>
                                <option value="state">State</option>
                                <option value="city">City</option>
                                <option value="postal_code">Postal Code</option>
                            </select>
                            <button className={sort === null ? null : sort ? "sorting-asc" : "sorting-dec"} onClick={onSordHandler}>
                                <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                                <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="button-filter">
                            <button className="add-user-btn" onClick={() => onAddHandler()}><i class="fa fa-plus" aria-hidden="true"></i></button>
                            <button className="add-filter-up-btn"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>
                        </div>

                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <div className="add-role-button-view">
                                    <div className="add-btn-group">
                                        <button disabled={isDisabled}><i class="fa fa-external-link" aria-hidden="true"></i></button>
                                        <button
                                            onClick={() => onMultipleRemoveHandler()}
                                            disabled={isDisabled}>X</button>
                                        <button disabled={isDisabled}>S</button>
                                        <button
                                            onClick={() => onDownloadHandler()}
                                            disabled={isDisabled}>
                                            <i class="fa fa-download" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.list.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="customer_shop">
                                                            <div className="form-check custom-checkbox mx-2">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="checkAll"
                                                                    onClick={(e) => chackboxFun('all', 0)}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkAll"
                                                                ></label>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <strong>Id</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Type</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Address</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Country</strong>
                                                        </th>
                                                        <th>
                                                            <strong>State</strong>
                                                        </th>
                                                        <th>
                                                            <strong>City</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Postal Code</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Status</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Action</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.list.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="form-check custom-checkbox checkbox-select mx-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id={`check${index}`}
                                                                                onClick={(e) => chackboxFun(e, item)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`check${index}`}
                                                                            ></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.loc_id}</td>
                                                                    <td className="caps-text">{item.loc_type}</td>
                                                                    <td>{item.address_1}</td>
                                                                    <td>{item.country}</td>
                                                                    <td>{item.state}</td>
                                                                    <td>{item.city}</td>
                                                                    <td>{item.postal_code}</td>
                                                                    <td>
                                                                        <Button
                                                                            className="btn-xs"
                                                                            variant={item.status == 1 ? "success" : "danger"}
                                                                            onClick={() => onStatusHandler(item)}
                                                                        >
                                                                            {item.status == 1 ? "Active" : "Inactive"}
                                                                        </Button>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <Link
                                                                                onClick={() => onEditHandler(item)}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </Link>

                                                                            <Button
                                                                                className="btn btn-danger shadow btn-xs sharp me-1"
                                                                                onClick={() => onDeleteHandler(item._id)}
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Button>

                                                                            <Link
                                                                                //onClick={() => onEditHandler(item)}
                                                                                className="btn btn-info shadow btn-xs sharp"
                                                                            >
                                                                                <i className="fa fa-external-link-square"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <h2>Data not found</h2>
                                                <p>Location data is empty or Try again search</p>
                                                <button type="button" onClick={onCleanHandler}>
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                    </>
                                    : null}

                                <div className="pagination-container">
                                    {parseInt(props.totalRecord) > 0 && props.showLoading == false ? (
                                        <div className="page-result alignResult">
                                            <p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.list.length) + " of " + "( " + props.totalRecord + " items )"}</p>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {parseInt(props.totalRecord) > 10 ? (
                                        <ReactPaginate
                                            containerClassName="pagination-gutter false pagination-circle pagination"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            breakLabel="..."
                                            nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            renderOnZeroPageCount={null}
                                            activeClassName="active"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={props.totalPage}
                                            //initialPage={active - 1}
                                            forcePage={pageNo - 1}
                                        />
                                    ) : null}
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </div>

                <Modal
                    className="fade bd-example-modal-sm"
                    size="lg"
                    show={addModal}
                >
                    <div className="add-user-modal-form-view">
                        <div className="modal-title-view">
                            <h3>{action == 1 ? "Add" : "Update"} Location</h3>
                            <p>Manage Company/Organization Information such as name, type, location address and contacts.
                            </p>
                        </div>
                        <div className="basic-form">
                            <form onSubmit={onSubmit}>
                                <div className="row">

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Location id
                                        </label>
                                        <input className="form-control" value={locId} onChange={(e) => setLocId(e.target.value)} />
                                        {errors.locId && <div className="text-danger fs-12">{errors.locId}</div>}
                                    </div>
                                    <div
                                        ref={wrapperRef}
                                        className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Location type
                                        </label>
                                        <div
                                            className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <input
                                                className="form-control text-capitalize"
                                                value={locationType}
                                                onClick={() => setIsType(true)}
                                                onChange={(e) => setLocationType(e.target.value)} />
                                            {isType ?
                                                <div className="autocomplete-dropdown">
                                                    <ul>
                                                        {
                                                            type.concat(props.locationTypeList).map((item, index) => (
                                                                <li key={index} onClick={() => locationTypeHanler(item)}>{item}</li>
                                                            ))
                                                        }

                                                    </ul>
                                                </div>
                                                : null}
                                        </div>

                                        {errors.locationType && <div className="text-danger fs-12">{errors.locationType}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Address
                                        </label>
                                        <input className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} />
                                        {errors.address && <div className="text-danger fs-12">{errors.address}</div>}
                                    </div>
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Alternative Address
                                        </label>
                                        <input className="form-control" value={addressTwo} onChange={(e) => setAddressTwo(e.target.value)} />
                                    </div>
                                    <div className="form-group form-group-select mb-3 col-6">
                                        <label className="mb-1">
                                            Country
                                        </label>
                                        <Select
                                            id="editCountry"
                                            name="editCountry"
                                            label="country"
                                            classNames="select-custom-form"
                                            options={updatedCountries}
                                            value={updatedCountries.filter(obj => obj.name === country)}
                                            placeholder=""
                                            onChange={onChangecountry}
                                        />
                                        {errors.country && <div className="text-danger fs-12">{errors.country}</div>}
                                    </div>

                                    <div className="form-group form-group-select mb-3 col-6">
                                        <label className="mb-1">
                                            State
                                        </label>
                                        <Select
                                            id="editState"
                                            name="editState"
                                            label="state"
                                            classNames="select-custom-form"
                                            placeholder=""
                                            options={updatedStates}
                                            value={updatedStates.filter(obj => obj.name === state)}
                                            onChange={onChangeState}
                                        />
                                        {errors.state && <div className="text-danger fs-12">{errors.state}</div>}
                                    </div>

                                    <div className="form-group form-group-select mb-3 col-6">
                                        <label className="mb-1">
                                            City
                                        </label>
                                        <Select
                                            id="editCity"
                                            name="editCity"
                                            label="city"
                                            placeholder=""
                                            options={updatedCitys}
                                            value={updatedCitys.filter(obj => obj.name === city)}
                                            onChange={onChangeCity}
                                        />
                                        {errors.city && <div className="text-danger fs-12">{errors.city}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Postal Code
                                        </label>
                                        <input className="form-control" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                                        {errors.postalCode && <div className="text-danger fs-12">{errors.postalCode}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Status
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                class="form-control select-control"
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}>
                                                <option></option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                        </div>
                                        {errors.status && <div className="text-danger fs-12">{errors.status}</div>}
                                    </div>



                                </div>
                                <div className="modal-button-group mt-4">
                                    <button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary role-form-btn">
                                        {action == 1 ? "Save" : "Update"}
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </Modal>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.location.screenVisible,
        showLoading: state.location.showLoading,
        list: state.location.list,
        totalPage: state.location.totalPage,
        totalRecord: state.location.totalRecord,
        locationTypeList: state.location.locationTypeList,
        countryList: state.country.countryList,
        stateList: state.country.stateList,
        cityList: state.country.cityList,
    };
};
export default connect(mapStateToProps)(Location);