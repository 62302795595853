import {
    LOADING_TIME_CARD,
    GET_TIME_CARD_LIST,
    GET_TIME_CARD_SINGLE_DETAILS,
    GET_GENERATE_TIME_CARD_DETAILS,
    GET_MANUAL_TIMESHEET,
    GET_GENERATED_REPORTS_DETAILS,
    GET_REPORT_DRILL_LIST,
    REPORT_PENDING_COUNT,
    REPORT_PROGRESS_STATUS
} from '../constants';

const initialState = {
    list: [],
    details: {},
    generateDetails: {},
    generatedDetails: [],
    totalPage: 0,
    totalRecord: 0,
    drillList: [],
    drillTotalPage: 0,
    drillTotalRecord: 0,
    pendingCount: 0,
    supervisorPendingCount: 0,
    stepCount: 0,
    legends: [],
    screenVisible: false,
    showLoading: false,
};

export function TimeCardReducer(state = initialState, action) {

    if (action.type === GET_TIME_CARD_LIST) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            list: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_TIME_CARD_SINGLE_DETAILS) {
        return {
            ...state,
            details: action.payload,
            screenVisible: true,
        };
    }

    if (action.type === GET_GENERATE_TIME_CARD_DETAILS) {
        return {
            ...state,
            generateDetails: action.payload,
            screenVisible: true,
        };
    }

    if (action.type === GET_GENERATED_REPORTS_DETAILS) {
        return {
            ...state,
            generatedDetails: action.payload.data,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            screenVisible: true,
        };
    }


    if (action.type === GET_MANUAL_TIMESHEET) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            list: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }
    if (action.type === GET_REPORT_DRILL_LIST) {
        return {
            ...state,
            drillTotalPage: action.payload.total_page,
            drillTotalRecord: action.payload.total_record,
            drillList: action.payload?.data.track_details ?? [],
            screenVisible: true,
            showLoading: false,
        };
    }
    if (action.type === REPORT_PENDING_COUNT) {
        return {
            ...state,
            pendingCount: action.payload?.count ?? 0,
            supervisorPendingCount: action.payload?.supervisor_pending_count ?? 0,
        };
    }
    if (action.type === REPORT_PROGRESS_STATUS) {
        return {
            ...state,
            stepCount: action.payload?.step ?? 0,
            legends: action.payload?.legends ?? [],
        };
    }
    if (action.type === LOADING_TIME_CARD) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


